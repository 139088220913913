import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Container } from '@mui/system';
import { Paper, Box, Grid, Typography, CircularProgress } from '@mui/material';

import { BaseAccordion } from 'components/Accordion/Accordion';
import { BaseCard } from 'components/Cards';
import { RemoteDataTable } from 'components/Tables';
import { FormWithAdditionaFilters } from 'components/Forms';
import { BaseButton } from 'components/Buttons';
import { AddNewNoteModalContent, CustomHeader, HistoryPriceModal } from '../shared';

import { useAppData, useNotifications, useUser } from 'context';
import { useQueryPagination, useSearchParams } from 'hooks';
import { capitalizeString } from 'utils/capitalizeString';
import {
	useMutateProductNotes,
	useProductNotes,
	useProductPresentations,
	useProductPrices,
	useProductTenders,
	useProductRegistration,
	useProductShortageInfo
} from 'services/queries';

import { NotificationsTypeEnum } from 'models/enums';
import { upgradeAccountMessage } from 'utils/consts/messages';
import { COMMON_DATE_FORMAT, euCountriesId } from 'utils/consts';
import { cardFields } from './cardField';
import { euTendersColumns, matchingATCCodeColumns, notesColumns, productPriceColumns } from './columns';
import { searchFilters, tenderFilters } from './searchFilters';
import { BaseModal } from 'components/Modals';

interface SearchInputsIntreface {
	countries: { name: string; id: string; code: string | number }[];
	presentation: { name: string; id: number }[];
	pack_size: { name: string; id: number }[];
	strength: { name: string; id: number }[];
}

interface tenderSearchInputsInterface {
	countries: { name: string; id: number; code: string | number; }[];
}

const initialSearchInputs = {
	countries: [],
	presentation: [],
	pack_size: [],
	strength: []
};

const initialTenderSearchInputs = {
	countries: []
};

const PRICES_TABLE_ID = '1';
const PROD_TABLE_ID = '2';
const TENDERS_TABLE_ID = '3';
const NOTES_TABLE_ID = '4';

export const NationalProduct = () => {
	const { uid } = useParams();
	const queryParameters = new URLSearchParams(window.location.search);
	const country = queryParameters.get('country');

	const { setHeaderElement } = useAppData();
	const { addNotification } = useNotifications();
	const { user } = useUser();

	const [
		searchInputs, 
		setSearchInputs, 
		resetSearchInputs
	] = useSearchParams<SearchInputsIntreface>(initialSearchInputs, PROD_TABLE_ID);

	const [
		tenderSearchInputs, 
		setTenderSearchInputs, 
		resetTenderSearchInputs
	] = useSearchParams<tenderSearchInputsInterface>(initialTenderSearchInputs, TENDERS_TABLE_ID);

	const [showAddNoteModal, setShowAddNoteModal] = useState<boolean>(false);
	const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);

	const {
		data: productDetails,
		// error,
		isLoading,
		isFetching
		// isFetched,
	} = useProductRegistration({
		params: { id: uid },
		queryParams: { country: country }
	});

	const {
		data: shortageInfo
	} = useProductShortageInfo({
		id: Number(uid), 
		queryParams: { is_active: true, eu_first: true }
	});

	const {
		postProductNotesAsync
		// postProductNotesLoading
	} = useMutateProductNotes();

	useEffect(() => {
		if (productDetails) {
			setHeaderElement(
				<CustomHeader shortageInfo={shortageInfo} productDetails={productDetails} registrationType={productDetails.registration_type} />
			);
		}
	}, [productDetails, shortageInfo, setHeaderElement]);

	const hasAccess = useMemo(() => user?.company?.is_paid, [user]);

	const onChangeHandler = (e, type) => {
		switch (type) {
			case 'tender':
				setTenderSearchInputs((prevInputs) => ({
					...prevInputs,
					[e.target.name]: e.target.value
				}));
				break;

			default:
				setSearchInputs((prevInputs) => ({
					...prevInputs,
					[e.target.name]: e.target.value
				}));
				break;
		}
	};

	const pricesParams = useMemo(() => ({ product_id: productDetails?.pack?.id }), [productDetails]);

	const pricesQueryParams = useMemo(() => ({ ordering: '-date' }), []);

	const {
		data: pricesData,
		state: pricesState,
		setPagination: pricesSetPagination,
		rowCount: pricesRowCount,
		setSorting: pricesSorting,
		pageCount: pricesPageCount,
		setPageCount: pricesSetPageCount
	} = useQueryPagination(useProductPrices, null, pricesParams, pricesQueryParams, { tableId: PRICES_TABLE_ID });

	const productQueryParams = useMemo(
		() => ({
			country__in: searchInputs.countries.map((val) => val.id).join(','),
			product_pack__presentation__in: searchInputs.presentation.map((val) => val.id).join(','),
			product_pack__pack_size__in: searchInputs.pack_size.map((val) => val.id).join(','),
			product_pack__active_ingredients__strength__in: searchInputs.strength.map((val) => val.id).join(','),
			atc_code: productDetails?.pack?.product?.atc_code?.id,
			exclude_product_id: productDetails?.pack?.product?.id,
			is_commercial_exclude: false,
			...(searchInputs.presentation.find(val => val.name === 'Injectables')
				? {
					is_injectable: true
				} : null)
		}),
		[	
			productDetails,
			searchInputs.countries,
			searchInputs.presentation,
			searchInputs.pack_size,
			searchInputs.strength,
		]
	);

	const {
		data: prodData,
		state: prodState,
		setPagination: prodSetPagination,
		rowCount: prodRowCount,
		setSorting: prodSorting,
		pageCount: prodPageCount,
		setPageCount: prodSetPageCount
	} = useQueryPagination(useProductPresentations, null, null, productQueryParams, {
		mandatory: ['atc_code', 'exclude_product_id'],
		tableId: PROD_TABLE_ID
	});

	const tendersQueryParams = useMemo(
		() => ({
			product: productDetails?.pack?.product?.id,
			country__in: tenderSearchInputs.countries
				.map((val) => val.id)
				.filter((id) => id !== euCountriesId)
				.join(',')
		}),
		[productDetails, tenderSearchInputs.countries]
	);
	const {
		data: tendersData,
		state: tendersState,
		setPagination: tendersSetPagination,
		rowCount: tendersRowCount,
		setSorting: tendersSorting,
		pageCount: tendersPageCount,
		setPageCount: tendersSetPageCount
	} = useQueryPagination(useProductTenders, null, null, tendersQueryParams, {
		mandatory: ['product'],
		sort: [{ id: 'lot__tender__publication_date', desc: true }],
		tableId: TENDERS_TABLE_ID
	});

	const notesParams = useMemo(() => ({ id: productDetails?.id }), [productDetails?.id]);

	const {
		data: notesData,
		state: notesState,
		setPagination: notesSetPagination,
		rowCount: notesRowCount
	} = useQueryPagination(useProductNotes, null, notesParams, null, { tableId: NOTES_TABLE_ID });

	const onAddNoteHandeler = async (noteData: any, { setSubmitting, resetForm, setErrors }: any) => {
		try {
			const addedNote =
				uid &&
				(await postProductNotesAsync({
					productId: productDetails?.id,
					noteData
				}));
			resetForm();

			addedNote &&
				addNotification({
					message: 'Note saved!',
					type: NotificationsTypeEnum.SUCCESS
				});
		} catch (err: any) {
			setErrors(err.data);
		} finally {
			setSubmitting(false);
			setShowAddNoteModal(false);
		}
	};

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<BaseCard
					loading={isLoading || isFetching}
					cardTitle={'National Product'}
					dataItem={productDetails}
					cardFields={cardFields}
					copyBrief
				/>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ borderRadius: '1rem' }} elevation={0}>
					<Box p={2} pt={4}>
						<Container maxWidth={false}>
							<Grid container>
								<Typography component='h3' fontSize={'16px'} fontWeight={600}>
									{`Pricing  ${hasAccess ? '' : ' - ' + upgradeAccountMessage}`}
								</Typography>
								{pricesRowCount > 0 && (
									<Box px={2} ml={'auto'}>
										<Box ml={2}>
											<BaseButton label={'History'} onClick={() => setShowHistoryModal(true)} />
										</Box>
									</Box>
								)}
							</Grid>
						</Container>
					</Box>
					{hasAccess && (
						<RemoteDataTable
							state={pricesState}
							data={pricesData && pricesData.length > 0 ? [pricesData[0]] : []}
							pageCount={pricesPageCount && 1}
							onRowsPerPageChange={pricesSetPageCount}
							onPaginationChange={pricesSetPagination}
							rowCount={pricesRowCount && 1}
							columns={productPriceColumns}
							enablePagination
							enableRowSelection={false}
							manualSorting={false}
							enableMultiSort={false}
							onSortingChange={pricesSorting}
						/>
					)}
				</Paper>
			</Grid>
			{productDetails?.pack?.product?.atc_code?.code && (
				<Grid item xs={12}>
					<Paper sx={{ borderRadius: '1rem' }} elevation={0}>
						<RemoteDataTable
							state={prodState}
							data={prodData}
							pageCount={prodPageCount}
							onRowsPerPageChange={prodSetPageCount}
							onPaginationChange={prodSetPagination}
							rowCount={prodRowCount || 0}
							columns={matchingATCCodeColumns}
							enableRowSelection={false}
							enablePagination
							manualSorting
							enableMultiSort={false}
							onSortingChange={prodSorting}
							renderCustomActions={({ table }) => (
								<Grid container px={2} pt={2}>
									<FormWithAdditionaFilters
										parentValue={productDetails.pack.product.atc_code.id}
										filters={searchFilters}
										searchInputs={searchInputs}
										onChangeHandler={(e) => onChangeHandler(e, 'product')}
										onResetHandler={resetSearchInputs}
										fullScreenChangeHandler={() => table.setIsFullScreen(!table.getState().isFullScreen)}
										isFullScreen={table.getState().isFullScreen}
									>
										<Grid item xs={6}>
											<Typography component='h3' fontSize={'16px'} fontWeight={600}>
												{`Products with matching ATC code - ${productDetails.pack.product.atc_code.code}`}
											</Typography>
										</Grid>
									</FormWithAdditionaFilters>
								</Grid>
							)}
						/>
					</Paper>
				</Grid>
			)}
			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{`EU tenders Including ${
								productDetails?.pack?.active_ingredients
									? productDetails.pack.active_ingredients
										.map((val) => capitalizeString(val.active_ingredient.name))
										.join(', ')
									: ' '
							}`}
							{tendersState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${tendersRowCount})`
							)}
						</Fragment>
					}
					fullWidth
				>
					<RemoteDataTable
						state={tendersState}
						columns={euTendersColumns}
						data={tendersData}
						pageCount={tendersPageCount}
						onRowsPerPageChange={tendersSetPageCount}
						enableRowSelection={false}
						// enableFullScreenToggle
						enablePagination
						manualSorting
						onPaginationChange={tendersSetPagination}
						rowCount={tendersRowCount}
						onSortingChange={tendersSorting}
						lastColumnAlignRight
						renderCustomActions={({ table }) => (
							<Grid container px={2}>
								<FormWithAdditionaFilters
									// parentValue={productDetails?.name}
									filters={tenderFilters}
									searchInputs={tenderSearchInputs}
									onChangeHandler={(e) => onChangeHandler(e, 'tender')}
									onResetHandler={resetTenderSearchInputs}
									gridWidth={5}
									fullScreenChangeHandler={() => table.setIsFullScreen(!table.getState().isFullScreen)}
									isFullScreen={table.getState().isFullScreen}
									compact
									showReset
								/>
							</Grid>
						)}
					/>
				</BaseAccordion>
			</Grid>

			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{'Notes '}
							{notesState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${notesRowCount})`
							)}
						</Fragment>
					}
					accordionActions={[
						{
							actionLabel: 'Add New Note',
							actionFn: () => setShowAddNoteModal(true)
						}
					]}
					fullWidth
				>
					<RemoteDataTable
						state={notesState}
						data={notesData}
						onPaginationChange={notesSetPagination}
						rowCount={notesRowCount}
						columns={notesColumns}
						enableRowSelection={false}
						enablePagination
						lastColumnAlignRight
					/>
				</BaseAccordion>
			</Grid>
			<Grid item xs={12}>
				<Typography variant={'body1'}>
					* Source: WHO ICTRP. Last updated {moment().startOf('week').format(COMMON_DATE_FORMAT)}, Pharma Footpath data
					is updated weekly. Please check WHO ICTRP for the latest data.
				</Typography>
			</Grid>
			<BaseModal show={showAddNoteModal} width={500}>
				<AddNewNoteModalContent
					onSubmit={onAddNoteHandeler}
					onCancel={() => setShowAddNoteModal((prevState) => !prevState)}
				/>
			</BaseModal>
			<BaseModal show={showHistoryModal} width={1200} close={() => setShowHistoryModal((prevState) => !prevState)}>
				<HistoryPriceModal
					product={productDetails?.pack?.id}
					onCancel={() => setShowHistoryModal((prevState) => !prevState)}
				/>
			</BaseModal>
		</Grid>
	);
};
