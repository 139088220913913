/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react';
import { INotification } from 'models/interfaces';

export const NotificationsContext = createContext<{
	notifications: INotification[];
	addNotification: (x: INotification) => void;
	removeNotification: (x: number|string) => void;
	fadeNotification: (x: number) => void;
		}>({
			notifications: [],
			addNotification: () => {},
			fadeNotification: () => {},
			removeNotification: () => {},
		});

export const useNotifications = () => {
	const context = useContext(NotificationsContext);

	return context;
};
