import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
	Button,
	Grid,
	Typography,
	Box,
	CircularProgress,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import QRCode from 'react-qr-code';
import { COLORS } from 'utils/consts';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';
import { AlertsTypesEnum } from 'models/enums';
import { useNotifications, useUser } from 'context';
import { confirmMFADeviceAssociation, initMFADeviceAssociation } from 'services/MFAServices';
import { BaseInput } from 'components/Inputs';

const StyledButton = styled(Button)(() => ({
	padding: '17px',
	borderRadius: MEDIUM_BORDER_RADIUS,
	width: '100%',
	marginBottom: '10px',
}));

const SectionTitle = styled(Typography)(() => ({
	fontSize: '14px',
	fontWeight: 600,
	margin: '14px 0',
	display: 'block',
}));

const QRContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: COLORS.white,
	borderRadius: MEDIUM_BORDER_RADIUS,
	margin: '20px auto 20px',
	maxWidth: '300px',
	width: '100%',
}));

const StepDescription = styled(Typography)(() => ({
	fontSize: '14px',
	marginBottom: '8px',
}));

export const MfaSetupSection = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	return (
		<>
			<Grid item xs={12}>
				<SectionTitle>Two-Factor Authentication (MFA)</SectionTitle>
				<Typography variant="body2">
					Protect your account with an additional layer of security using an authenticator app.
				</Typography>
			</Grid>
			<Grid item xs={4} md={4}>
				<StyledButton disableElevation={true} color="primary" variant="outlined" onClick={handleOpenModal}>
					Configure MFA
				</StyledButton>
			</Grid>

			<MfaSetupModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</>
	);
};

type MfaSetupModalProps = {
	open: boolean;
	onClose: () => void;
};

export const MfaSetupModal = ({ open, onClose }: MfaSetupModalProps) => {
	const [step, setStep] = useState(1);
	const [qrCodeData, setQrCodeData] = useState<string | null>(null);
	const [userCode, setUserCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { user } = useUser();
	const { addNotification } = useNotifications();

	const initMfaSetup = async () => {
		try {
			setIsLoading(true);
			const response = await initMFADeviceAssociation();

			setQrCodeData(
				`otpauth://totp/PharmaFootpath:${encodeURIComponent(user?.email ?? '')}?secret=${response.mfa_registration_code}&issuer=PharmaFootpath&digits=6&period=30`
			);

			setStep(2);
		} catch (error) {
			addNotification({
				type: AlertsTypesEnum.ERROR,
				message: 'Failed to generate QR code. Please try again.',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const verifyMfaCode = async () => {
		try {
			setIsLoading(true);

			const response = await confirmMFADeviceAssociation({ user_code: userCode });
			setStep(3);
			addNotification({
				type: AlertsTypesEnum.SUCCESS,
				message: 'MFA successfully configured',
			});
		} catch (error) {
			addNotification({
				type: AlertsTypesEnum.ERROR,
				message: 'Failed to confirm MFA. Please try again.',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleClose = () => {
		setStep(1);
		setUserCode('');
		onClose();
	};

	useEffect(() => {
		if (open && step === 1) {
			initMfaSetup();
		}
	}, [open, step]);

	const handleChangeCode = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value) && value.length <= 6) {
			setUserCode(value);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>{step < 3 ? 'Configure Two-Factor Authentication' : 'MFA Configuration Complete'}</DialogTitle>
			<DialogContent>
				{step === 1 && (
					<Box display="flex" justifyContent="center" padding="20px">
						<CircularProgress />
					</Box>
				)}

				{step === 2 && (
					<>
						<StepDescription>
							Scan the QR code with your authenticator app (Google Authenticator, Microsoft Authenticator,
							or Authy)
						</StepDescription>

						{qrCodeData && (
							<QRContainer>
								<QRCode value={qrCodeData} size={196} />
							</QRContainer>
						)}

						<BaseInput
							fullWidth
							name="verification_code"
							label="Enter the 6-digit code from your app"
							size="small"
							value={userCode}
							onChange={handleChangeCode}
							placeholder="⋅⋅⋅⋅⋅⋅"
							
							onKeyDown={(e) => {
								if (e.key === 'Enter' && userCode.length === 6 && !isLoading) {
									verifyMfaCode();
								}
							}}
							inputProps={{
								inputMode: 'numeric',
								autoComplete: 'one-time-code',
								autoFocus: true,
							}}
						/>
					</>
				)}

				{step === 3 && (
					<>
						<Typography variant="subtitle1" gutterBottom>
							Your two-factor authentication has been successfully set up.
						</Typography>
						<Typography variant="body2">
							From now on, you&apos;ll be asked to provide a verification code from your authenticator app
							when signing in to your account.
						</Typography>
					</>
				)}
			</DialogContent>
			<DialogActions>
				{step === 2 && (
					<>
						<Button onClick={handleClose} disabled={isLoading}>
							Cancel
						</Button>
						<Button
							onClick={verifyMfaCode}
							variant="contained"
							color="primary"
							disabled={userCode.length !== 6 || isLoading}
						>
							{isLoading ? <CircularProgress size={24} /> : 'Verify'}
						</Button>
					</>
				)}

				{step === 3 && (
					<Button onClick={handleClose} variant="contained" color="primary">
						Close
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
