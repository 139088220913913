import React from 'react';
import { ApplicationRoutesEnum } from 'models/enums';
import {
	// DashboardIcon,
	ProductIcon,
	CompanyIcon,
	// AtcCodeIcon,
	ArrowRightArrorLeft,
} from '../../components/Icons';

export const DRAWER_ITEMS = [
	// {
	//   title: 'Dashboard',
	//   route: ApplicationRoutesEnum.HOME,
	//   icon: <DashboardIcon stroke='#000' />,
	// },
	{
		title: 'Product',
		route: ApplicationRoutesEnum.PRODUCT_EXPLORER,
		icon: <ProductIcon stroke='#000' />,
	},
	{
		title: 'Parallel Trade',
		route: ApplicationRoutesEnum.PARALLEL_TRADE,
		icon: <ArrowRightArrorLeft stroke='#000' />,
	},
	// { title: "Companies", route: ApplicationRoutesEnum.COMPANY_EXPLORER, icon: <CompanyIcon stroke='#000' /> },
	// {
	// 	title: 'ATC Code',
	// 	route: ApplicationRoutesEnum.ATC_CODE_EXPLORER,
	// 	icon: <AtcCodeIcon stroke='#000' />,
	// },
	{
		title: 'Shortage',
		route: ApplicationRoutesEnum.SHORTAGES,
		icon: <CompanyIcon stroke='#000' />,
	},
	// {
	// 	title: 'Tender',
	// 	route: ApplicationRoutesEnum.TENDER_EXPLORER,
	// },
	{
		title: 'EU WDA LICENSE',
		route: ApplicationRoutesEnum.WDA_LICENSES,
	},
];
