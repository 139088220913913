const REDIRECT_URL_KEY = 'redirectUrl';

export const saveRedirectUrl = (url: string): void => {
	localStorage.setItem(REDIRECT_URL_KEY, url);
};

export const getRedirectUrl = (): string | null => {
	return localStorage.getItem(REDIRECT_URL_KEY);
};

export const clearRedirectUrl = (): void => {
	localStorage.removeItem(REDIRECT_URL_KEY);
};
