import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Avatar, Badge, Autocomplete, Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useUser, useNotifications } from 'context';
import { BaseInput, BasePasswordInput, TextFieldWrapper } from 'components/Inputs';
import { initialFormValues } from 'forms/editProfile';
import { InfoTooltip } from 'components/Tooltips';
import { COLORS } from 'utils/consts';
import { cognitoChangePassword, editUserMe } from 'services';
import { getCurrenciesV1 } from 'services/CurrencyServices';
import { AlertsTypesEnum, CountryFlagsEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS, SMALL_VARIANT_FONT_SIZE, SMALL_VARIANT_INPUT_HEIGHT } from 'utils/consts/themeConsts';
import { useAuth } from 'react-oidc-context';
import { BaseModal } from 'components/Modals';
import { MfaSetupSection } from './MfaSetupSection';

const StyledAvatar = styled(Avatar)({
	backgroundColor: COLORS.secondary,
	color: COLORS.black,
	width: 127,
	height: 127,
	fontSize: '36px',
});

const EditProfileIcon = styled('img')(() => ({
	width: '9px',
	height: '9px',
}));

const StyledBadge = styled('label')({
	width: '20px',
	height: '20px',
	top: '-25px',
	left: '90px',
	position: 'relative',
});

const StyledButton = styled(Button)(() => ({
	padding: '17px',
	borderRadius: MEDIUM_BORDER_RADIUS,
	width: '100%',
	marginBottom: '40px',
}));

const DropdownLabel = styled('label')(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 600,
	margin: '14px 0',
	display: 'inline-block',
	fontFamily: theme.typography.fontFamily,
}));

const StyledLink = styled(Link)(() => ({
	fontWeight: 500,
	fontSize: '14px',
	lineHeight: 1.5,
	marginTop: '6px',
	textDecoration: 'none',
	float: 'right',
	cursor: 'pointer',
	zIndex: 10,
	position: 'relative',
}));

export const EditProfile = () => {
	const { user, setUserData } = useUser();
	const auth = useAuth();
	const navigate = useNavigate();
	const [isChangingPassword, setIsChangingPassword] = useState(false);
	const [imageAvatar, setImageAvatar] = useState<any>({});
	const [showEditSuccessModal, setShowEditSuccessModal] = useState<boolean>(false);
	const { addNotification } = useNotifications();
	const [currencies, setCurrencies] = useState<any>(null);
	const [currencyValue, setCurrencyValue] = useState<any>(null);

	const userInitials = user ? user?.first_name?.split('')[0] + user?.last_name?.split('')[0] : '';

	if (user) {
		initialFormValues.first_name = user.first_name;
		initialFormValues.last_name = user.last_name;
		initialFormValues.email = user.email;
	}

	const editProfileSchema = Yup.object().shape({
		first_name: Yup.string().required('Required'),
		last_name: Yup.string().required('Required'),
		email: Yup.string().email('Invalid email').required('Required'),
		current_password: isChangingPassword ? Yup.string().required('Required') : Yup.string(),
		password: isChangingPassword
			? Yup.string().min(8, 'This password is too short.').max(50, 'This password is too long.').required('Required')
			: Yup.string(),
		confirmPassword: isChangingPassword ? Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match') : Yup.string(),
	});

	useEffect(() => {
		const getData = async () => {
			try {
				const response = await getCurrenciesV1({});

				const formattedCurrencies = response?.map((currency: any) => {
					return {
						id: currency.id,
						code: CountryFlagsEnum[currency.code],
						label: currency.name,
					};
				});

				const userCurrency = formattedCurrencies.find((currency) => currency.id === user?.currency);

				setCurrencies(formattedCurrencies);
				if (userCurrency) setCurrencyValue(userCurrency);
			} catch (err: any) {
				const message = err?.data?.detail ? err.data.detail : 'There has been an error while getting currencies';
				addNotification({ message, type: AlertsTypesEnum.ERROR });
				setCurrencies([]);
			}
		};
		getData();
	}, []);

	const onEditProfileHandler = async (values: any, { setSubmitting, setErrors }: any) => {
		try {
			const formData = {
				first_name: values.first_name,
				last_name: values.last_name,
				email: values.email,
				avatar: imageAvatar.image && imageAvatar.image,
				currency: currencyValue ? currencyValue.id : null,
			};

			if(auth.user && isChangingPassword) {
				const response = await cognitoChangePassword({
					access_token: auth.user.access_token,
					current_password: values.current_password,
					new_password: values.password,
				});

				if (response.error) {
					addNotification({
						type: AlertsTypesEnum.ERROR,
						message: response.error,
					});

					return;
				}
			}
			
			const userMeRes = await editUserMe(formData);
			
			if (userMeRes) {
				const editedUser = { ...user };
				editedUser.first_name = userMeRes.first_name;
				editedUser.last_name = userMeRes.last_name;
				editedUser.email = userMeRes.email;
				editedUser.avatar = userMeRes.avatar;
				editedUser.currency = userMeRes.currency;
				setUserData(editedUser);
				setShowEditSuccessModal(true);
			}
		} catch (err: any) {
			if (err.data.detail) {
				addNotification({
					type: AlertsTypesEnum.ERROR,
					message: err.data.detail,
				});
			} else {
				setErrors(err.data);
			}
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageUpload = (e: any) => {
		const image = e.target.files[0];
		const reader = new FileReader();
		reader.readAsArrayBuffer(image);
		reader.onload = () => {
			//@ts-ignore
			const blob = new Blob([reader.result]);
			const blobImageURI = URL.createObjectURL(blob);
			setImageAvatar({ image, imageBlob: blobImageURI && blobImageURI });
		};
	};

	const handleCloseModal = () => {
		setShowEditSuccessModal(false);
	};

	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					{imageAvatar.imageBlob || (user && user.avatar) ? (
						<StyledAvatar alt='Profile image' src={imageAvatar.imageBlob || (user && user.avatar)} />
					) : (
						<StyledAvatar>{userInitials}</StyledAvatar>
					)}
					<StyledBadge htmlFor='avatar'>
						<Badge
							sx={{ width: '20px', height: '20px', cursor: 'pointer' }}
							badgeContent={<EditProfileIcon alt='Edit progile icon' src={require('assets/icons/' + 'edit_profile_white.png')} />}
							color='primary'
						></Badge>
					</StyledBadge>
					<input
						type='file'
						accept='image/png, image/jpeg'
						id='avatar'
						style={{ display: 'none' }}
						onChange={handleImageUpload}
					/>
				</Grid>
				<Grid item xs={12}>
					<Formik initialValues={initialFormValues} validationSchema={editProfileSchema} onSubmit={onEditProfileHandler}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={2}>
									<Grid item container spacing={2} xs={6} md={6} sx={{ marginBottom: '-20px', marginLeft: '0px' }}>
										<Grid item xs={6} md={6} sx={{ marginBottom: '-20px' }}>
											<BaseInput
												fullWidth
												name='first_name'
												label='First Name'
												value={values.first_name}
												onChange={handleChange}
												onBlur={handleBlur}
												inputProps={{ sx: { backgroundColor: COLORS.white } }}
												error={touched.first_name && Boolean(errors.first_name)}
												helperText={touched.first_name ? errors.first_name : ''}
												disabled={user?.is_admin}
											/>
										</Grid>
										<Grid item xs={6} md={6} sx={{ marginBottom: '-20px' }}>
											<BaseInput
												fullWidth
												name='last_name'
												label='Last Name'
												value={values.last_name}
												onChange={handleChange}
												onBlur={handleBlur}
												inputProps={{ sx: { backgroundColor: COLORS.white } }}
												error={touched.last_name && Boolean(errors.last_name)}
												helperText={touched.last_name ? errors.last_name : ''}
												disabled={user?.is_admin}
											/>
											<StyledLink onClick={() => setIsChangingPassword(!isChangingPassword)}>
												{isChangingPassword ? 'CANCEL PASSWORD CHANGE' : 'CHANGE PASSWORD'}
											</StyledLink>
										</Grid>
										<Grid item xs={12}>
											<BaseInput
												fullWidth
												name='email'
												label='E-mail'
												placeholder='Your email is set by the company administrator'
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur}
												inputProps={{ sx: { backgroundColor: COLORS.white } }}
												error={touched.email && Boolean(errors.email)}
												helperText={touched.email ? errors.email : ''}
											/>
										</Grid>
										{isChangingPassword && (
											<Grid item xs={12} sx={{ marginTop: '-10px' }}>
												{currencies && currencies.length > 0 ? (
													<>
														<DropdownLabel>Currency to display prices in</DropdownLabel>
														<Autocomplete
															size='small'
															id='country-select'
															options={currencies}
															onChange={(event: any, newValue: string | null) => {
																setCurrencyValue(newValue);
															}}
															value={currencyValue}
															getOptionLabel={(option: any) => option?.label}
															renderOption={(props, option) => (
																<Box
																	component='li'
																	sx={{
																		'& > img': {
																			mr: 3,
																			flexShrink: 0,
																			height: '18px',
																		},
																	}}
																	{...props}
																>
																	<img
																		loading='lazy'
																		width='20'
																		src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
																		srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
																		style={{ borderRadius: '100%' }}
																		alt=''
																	/>
																	{option?.label}
																</Box>
															)}
															renderInput={(params) => (
																<TextFieldWrapper
																	{...params}
																	label='Choose Currency'
																	sx={{
																		maxHeight: SMALL_VARIANT_INPUT_HEIGHT,
																		fontSize: SMALL_VARIANT_FONT_SIZE,
																		borderRadius: MEDIUM_BORDER_RADIUS,
																		backgroundColor: COLORS.white,
																	}}
																/>
															)}
															sx={{
																maxHeight: SMALL_VARIANT_INPUT_HEIGHT,
																fontSize: SMALL_VARIANT_FONT_SIZE,
																borderRadius: MEDIUM_BORDER_RADIUS,
																backgroundColor: COLORS.white,
															}}
														/>
													</>
												) : null}
											</Grid>
										)}
										<MfaSetupSection />
										<Grid container spacing={2} sx={{ marginTop: '40px', marginLeft: '0px' }}>
											<Grid item xs={4}>
												<StyledButton
													disabled={isSubmitting}
													disableElevation={true}
													color='primary'
													variant='contained'
													type='submit'
												>
                          Save
												</StyledButton>
											</Grid>
											<Grid item xs={4}>
												<StyledButton disableElevation={true} color='primary' variant='outlined' onClick={() => navigate(-1)}>
                          Cancel
												</StyledButton>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={1} md={1}></Grid>
									<Grid item xs={4} md={4}>
										{isChangingPassword ? (
											<>
												<Grid item xs={12} sx={{ marginTop: '-20px' }}>
													<BasePasswordInput
														fullWidth
														name='current_password'
														label='Current Password'
														value={values.current_password}
														onChange={handleChange}
														onBlur={handleBlur}
														inputProps={{
															sx: { backgroundColor: COLORS.white },
														}}
														error={touched.current_password && Boolean(errors.current_password)}
														helperText={touched.current_password ? errors.current_password : ''}
														labelInfo={
															<InfoTooltip maxWidth={400}>
																<Typography sx={{ pb: 2 }}>
																	<strong>Create strong password</strong>
																</Typography>{' '}
																<Typography>
                                  Create strong password At least 12 characters — the more characters, the better. A mixture of
                                  both uppercase and lowercase letters. A mixture of letters and numbers. Inclusion of at least
                                  one special character, e.g., ! @ # ? ]
																</Typography>
															</InfoTooltip>
														}
													/>
												</Grid>
												<Grid item xs={12} sx={{ marginTop: '22px' }}>
													<BasePasswordInput
														fullWidth
														name='password'
														label='Enter New Password'
														value={values.password}
														onChange={handleChange}
														onBlur={handleBlur}
														inputProps={{
															sx: { backgroundColor: COLORS.white },
														}}
														error={touched.password && Boolean(errors.password)}
														helperText={touched.password ? errors.password : ''}
													/>
												</Grid>
												<Grid item xs={12} sx={{ marginTop: '22px' }}>
													<BasePasswordInput
														fullWidth
														name='confirmPassword'
														label='Repeat New Password'
														value={values.confirmPassword}
														onChange={handleChange}
														onBlur={handleBlur}
														inputProps={{
															sx: { backgroundColor: COLORS.white },
														}}
														error={touched.confirmPassword && Boolean(errors.confirmPassword)}
														helperText={touched.confirmPassword ? errors.confirmPassword : ''}
													/>
												</Grid>
											</>
										) : (
											<Grid item xs={12} sx={{ marginTop: '-13px' }}>
												{currencies && currencies.length > 0 ? (
													<>
														<DropdownLabel>Currency to display prices in</DropdownLabel>
														<Autocomplete
															size='small'
															id='country-select'
															options={currencies}
															onChange={(event: any, newValue: string | null) => {
																setCurrencyValue(newValue);
															}}
															value={currencyValue}
															getOptionLabel={(option: any) => option?.label}
															renderOption={(props, option) => (
																<Box
																	component='li'
																	sx={{
																		'& > img': {
																			mr: 3,
																			flexShrink: 0,
																			height: '18px',
																		},
																	}}
																	{...props}
																>
																	<img
																		loading='lazy'
																		width='20'
																		src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
																		srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
																		style={{ borderRadius: '100%' }}
																		alt=''
																	/>
																	{option?.label}
																</Box>
															)}
															renderInput={(params) => (
																<TextFieldWrapper
																	{...params}
																	label='Choose Currency'
																	sx={{
																		maxHeight: SMALL_VARIANT_INPUT_HEIGHT,
																		fontSize: SMALL_VARIANT_FONT_SIZE,
																		borderRadius: MEDIUM_BORDER_RADIUS,
																		backgroundColor: COLORS.white,
																	}}
																/>
															)}
															sx={{
																maxHeight: SMALL_VARIANT_INPUT_HEIGHT,
																fontSize: SMALL_VARIANT_FONT_SIZE,
																borderRadius: MEDIUM_BORDER_RADIUS,
																backgroundColor: COLORS.white,
															}}
														/>
													</>
												) : null}
											</Grid>
										)}
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</Grid>
			</Grid>
			<BaseModal show={showEditSuccessModal} modalTitle={'Profile updated'} close={handleCloseModal}></BaseModal>
		</>
	);
};