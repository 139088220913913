import React, { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import {
	Box,
	Typography,
	ListItem,
	ListItemText,
	Select,
	Checkbox,
	OutlinedInput,
} from '@mui/material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { MatFormLabel } from '../';
import { useReferenceData } from 'context';
import { getFlagEmoji } from 'utils';
import { COLORS, euCountriesCode, euCountriesId, euCountriesName } from 'utils/consts';
import {
	MaterialUIInputSizeType,
	MaterialUIInputVariantType,
} from 'models/types';
import { MaterialUISizeEnum } from 'models/enums';
import {
	MEDIUM_BORDER_RADIUS,
	SMALL_VARIANT_FONT_SIZE,
	SMALL_VARIANT_INPUT_HEIGHT,
} from 'utils/consts/themeConsts';

interface CountriesSelectInputInterface {
	onChange?: (x: any) => void;
	onBlur?: (x: any) => void;
	value?: any;
	name: string;
	data?: any[];
	label: string;
	labelInfo?: ReactNode;
	placeholder?: string;
	autoComplete?: string;
	variant?: MaterialUIInputVariantType;
	autoWidth?: boolean;
	fullWidth?: boolean;
	error?: boolean;
	helperText?: string;
	sx?: any;
	inputProps?: any;
	size?: MaterialUIInputSizeType;
}

export const CountriesSelectInput: FC<CountriesSelectInputInterface> = ({
	label,
	labelInfo,
	placeholder,
	name,
	fullWidth,
	onChange,
	value,
	size = 'small',
}) => {
	const {
		referenceData: { 
			countries, 
			euCountries 
		}} = useReferenceData();
	const countriesWithEu = useMemo(() => {
		if (countries && countries.length) {
			return [
				{ name: euCountriesName, code: euCountriesCode, id: euCountriesId },
				...countries,
			];
		}
		return countries;
	}, [countries]);

	const [selected, setSelected] = useState<{ id: number; name: string }[]>(
		value.map((val) => ({ id: val.id, name: val.name }))
	);

	const handleChange = (index: number) => {
		const value = countriesWithEu[index];
		let selectedCountries = selected.some((country) => country.id === value.id)
			? selected.filter((country) => country.id !== value.id)
			: [...selected, { id: value.id, name: value.name }];

		if (
			value.id === euCountriesId &&
			selectedCountries.some((country) => country.id === value.id)
		) {
			const mergedSelection = [
				...new Set([...euCountries, ...selectedCountries]),
			];
			selectedCountries = [
				...mergedSelection
					.reduce((map, obj) => map.set(obj.id, obj), new Map())
					.values(),
			];
		} else if (value.id === euCountriesId) {
			selectedCountries = selectedCountries.filter(
				(val) => !euCountries.some((country) => country.id === val.id)
			);
		}

		onChange && onChange({ target: { name, value: selectedCountries } });
	};

	useEffect(() => {
		setSelected(value);
	}, [value]);

	function renderRow(props: ListChildComponentProps) {
		const { index, style, data } = props;
		const item = data[index];
		return (
			<ListItem
				style={style}
				sx={{
					cursor: 'pointer',
					'&:hover': {
						background: COLORS.transparentBlue,
					},
				}}
				key={item.name}
				value={item.name}
				className='test'
				onClick={() => handleChange(index)}
			>
				<Box display='flex' alignItems='center'>
					<Checkbox
						size={MaterialUISizeEnum.SMALL}
						checked={selected.some((val) => val.id === item.id)}
					/>
					<Box mr={1}>{getFlagEmoji(item.code)}</Box>
					<ListItemText disableTypography={true} primary={item.name} />
				</Box>
			</ListItem>
		);
	}

	return (
		<Box>
			<MatFormLabel id={name}>
				{label}
				{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
			</MatFormLabel>
			<Select
				displayEmpty
				id={name}
				multiple
				fullWidth={fullWidth}
				value={selected}
				input={<OutlinedInput />}
				renderValue={(selected) => {
					const selectedCountries = countriesWithEu
						.filter((country) =>
							selected.some((item) => item.id === country.id)
						)
						.map((country) => country.name);
					if (selectedCountries.length) {
						return selectedCountries.join(', ');
					}
					return (
						<Typography fontSize={14} variant='body1' color={COLORS.mediumGray}>
							{placeholder}
						</Typography>
					);
				}}
				sx={{
					height: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_INPUT_HEIGHT
						: undefined,
					fontSize: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_FONT_SIZE
						: undefined,
					borderRadius: MEDIUM_BORDER_RADIUS,
					backgroundColor: COLORS.white,
				}}
			>
				<Box
					sx={{
						width: '100%',
						fontSize: size === MaterialUISizeEnum.SMALL
							? SMALL_VARIANT_FONT_SIZE
							: undefined,
						height: 400,
						bgcolor: 'background.paper',
					}}
				>
					<FixedSizeList
						itemData={countriesWithEu}
						height={400}
						width={'100%'}
						itemSize={SMALL_VARIANT_INPUT_HEIGHT}
						itemCount={countriesWithEu?.length}
						overscanCount={6}
					>
						{renderRow}
					</FixedSizeList>
				</Box>
			</Select>
		</Box>
	);
};
