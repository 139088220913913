/* eslint-disable react/prop-types */
import React from 'react';

export const ParallelImportIcon = ({ stroke = '#000', size = 16, ...props }) => (
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path 
				fill="transparent" 
				stroke={stroke}
				strokeOpacity=".8" 
				// strokeWidth="1.75"
				d="M8 15.335A7.333 7.333 0 1 0 8 .668a7.333 7.333 0 0 0 0 14.667Z"
			/>
			<path 
				fill={stroke}
				fillOpacity=".8" 
				// strokeWidth="1.75"
				d="M4 11V5h2.651c.43 0 .814.091 1.152.274.341.182.607.436.798.763.192.323.287.688.287 1.094 0 .438-.094.825-.282 1.16-.188.333-.452.59-.79.772a2.353 2.353 0 0 1-1.142.274h-1.01V11H4Zm2.576-3.137a.568.568 0 0 0 .323-.097c.1-.065.179-.153.238-.265a.783.783 0 0 0 .088-.37.594.594 0 0 0-.088-.305.722.722 0 0 0-.238-.251.557.557 0 0 0-.318-.101h-.918v1.39h.913ZM11.694 11H10.03V5h1.664v6Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z"/>
			</clipPath>
		</defs>
	</svg>
);
