import { AuthContextProps, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';

let currentAuth: AuthContextProps | null = null;

type LogoutCallback = () => void;

interface AuthTokenService {
	setAuthInstance: (auth: AuthContextProps) => void;
	getAccessToken: () => string | null;
	isAuthenticated: () => boolean;
	getUser: () => User | null;
	logout: (callback?: LogoutCallback) => void;
}

export const authTokenService: AuthTokenService = {
	setAuthInstance: (auth: AuthContextProps): void => {
		currentAuth = auth;
	},

	getAccessToken: (): string | null => {
		return currentAuth?.user?.access_token || null;
	},

	isAuthenticated: (): boolean => {
		return !!currentAuth?.isAuthenticated && !!currentAuth?.user;
	},

	getUser: (): User | null => {
		return currentAuth?.user || null;
	},

	logout: (callback?: LogoutCallback): void => {
		if (currentAuth) {
			currentAuth.signoutRedirect({
				extraQueryParams: {
					client_id: currentAuth.settings.client_id,
					logout_uri: window.location.origin,
				},
			});
			currentAuth.removeUser();
			if (callback) callback();
		}
	},
};
