import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'react-oidc-context';

import App from 'App';
import reportWebVitals from 'reportWebVitals';
import 'index.css';
import { AppDataContextProvider, UserContextProvider, NotificationsContextProvider } from 'context';
import { WebStorageStateStore } from 'oidc-client-ts';
import { getRedirectUrl, clearRedirectUrl } from 'services/redirectUrlService';

const cognitoAuthConfig = {
	authority: `${process.env.REACT_APP_COGNITO_SERVER_URL}/${process.env.REACT_APP_COGNITO_POOL_ID}`,
	client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
	redirect_uri: window.location.origin,
	logout_uri: window.location.origin,
	response_type: 'code',
	scope: 'aws.cognito.signin.user.admin email openid phone',
	userStore: new WebStorageStateStore({
		store: window.localStorage,
	}),
	automaticSilentRenew: true,
	onSigninCallback: () => {
		const redirectUrl = getRedirectUrl();
		if (redirectUrl) {
			clearRedirectUrl();
			try {
				new URL(window.location.origin + redirectUrl);
				window.location.replace(window.location.origin + redirectUrl);
			} catch (error) {
				console.error('Invalid redirect URL:', redirectUrl);
				window.history.replaceState({}, document.title, window.location.pathname);
			}
		} else {
			window.history.replaceState({}, document.title, window.location.pathname);
		}
	},
};

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { refetchOnWindowFocus: false, staleTime: 30_000, retry: 0, cacheTime: 1000 * 60 * 60 * 24 },
	},
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
	// <React.StrictMode>
	<AuthProvider {...cognitoAuthConfig}>
		<QueryClientProvider client={queryClient}>
			<AppDataContextProvider>
				<UserContextProvider>
					<NotificationsContextProvider>
						<App />
					</NotificationsContextProvider>
				</UserContextProvider>
			</AppDataContextProvider>
		</QueryClientProvider>
	</AuthProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
