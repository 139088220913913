import axios from 'axios';
import { useState } from 'react';
import { authTokenService } from 'services/Clients/authTokenService';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
	headers: {
		'content-type': 'application/json',
	},
});

instance.interceptors.request.use(
	(config) => {
		if (!config.headers) config.headers = new axios.AxiosHeaders();

		const token = authTokenService.getAccessToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

instance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.code === 'ERR_NETWORK') {
			networkErrorCallback?.(true);
		} else if (error.response && error.response.status === 429) {
			tooManyRequestsCallback?.({
				state: true,
				detail: error.response?.data?.detail,
			});
		} else if (error.response && error.response.status >= 500) {
			appIsDeployingCallback?.(true);
		}
		return Promise.reject(error);
	}
);

let tooManyRequestsCallback: any = null;
let appIsDeployingCallback: any = null;
let networkErrorCallback: any = null;

export function useAxiosInterceptors() {
	const [tooManyRequests, setTooManyRequests] = useState({ state: false, detail: '' });
	const [appIsDeploying, setAppIsDeploying] = useState(false);
	const [networkError, setNetworkError] = useState(false);

	tooManyRequestsCallback = setTooManyRequests;
	appIsDeployingCallback = setAppIsDeploying;
	networkErrorCallback = setNetworkError;

	return { errors: { tooManyRequests, appIsDeploying, networkError } };
}

export default instance;
