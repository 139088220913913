import * as Yup from 'yup';

export const initialFormValues = {
	first_name: '',
	last_name: '',
	email: '',
	company_name: '',
	terms: true,
	privacyPolicy: true
};

export const registerSchema = Yup.object().shape({
	first_name: Yup.string().trim().required('Required'),
	last_name: Yup.string().trim().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	company_name: Yup.string().trim().required('Required'),
	terms: Yup.boolean().oneOf([true], 'You must accept the Terms and Conditions'),
	privacyPolicy: Yup.boolean().oneOf([true], 'You must accept the Privacy Policy')
});
