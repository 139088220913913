import { useState, useEffect, Dispatch, SetStateAction } from 'react';

interface Option {
	name: string;
	id?: string | number;
}

interface ComboInput {
	ingredient: Option | null;
	strength: Option[];
}

interface IngredientAndStrengths {
	ingredients: ComboInput[];
	isExactIngredients: boolean;
}


interface BaseFilter {
	searchName?: string;
	productName?: string;
	countries?: { name: string; id: string | number; code: string | number }[];
	destination_countries?: { name: string; id: number; code: string | number }[];
	origin_countries?: { name: string; id: number; code: string | number }[];
	presentation?: { name: string; id: number }[];
	pack_size?: { name: string; id: number }[];
	strength?: { name: string; id: number }[];
	active_ingredient?: { name: string; id: number }[];
	is_commercial_exclude?: boolean;
	is_empty_price_exclude?: boolean;
	closed_tenders?: boolean;
	is_non_active_exclude?: boolean;
	is_exclude_eu?: boolean;
	ingredient_and_strengths?: IngredientAndStrengths;
	ma_holder?: { name: string; id: number }[];
}

interface Filters extends BaseFilter {
	[key: string]: any;
}


export const useSearchParams = <T extends Filters>(
	initialState: T,
	tableId?: string
): [T, Dispatch<SetStateAction<T>>, () => void] => {
	const [searchParams, setSearchParams] = useState(initialState);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const sParam = params.get(tableId ? `s${tableId}` : 's');

		let newParams = { 
			...initialState, 
			...(initialState.ingredient_and_strengths && { 
				ingredient_and_strengths: { ingredients: [{ ingredient: null, strength: [] }], isExactIngredients: false }
			})
		};


		if (sParam !== null) {
			const decodedParams = JSON.parse(sParam);
			newParams = { ...newParams, ...decodedParams };
		}

		if (JSON.stringify(searchParams) !== JSON.stringify(newParams)) {
			setSearchParams(newParams);
		}

	}, [JSON.stringify(initialState), location.search, location.pathname, tableId]);

	const setParams = (newParams: SetStateAction<T>) => {
		setSearchParams((prevParams) => {
			const mergedParams = typeof newParams === 'function'
				? (newParams as (prevState: T) => T)(prevParams)
				: newParams;

			// Reset dependent fields if productName changes
			const shouldReset = prevParams.productName !== mergedParams.productName;

			const resetFields = (params: T): T => ({
				...params,
				countries: initialState.countries,
				presentation: initialState.presentation,
				pack_size: initialState.pack_size,
				strength: initialState.strength,
				active_ingredient: initialState.active_ingredient,
				ingredient_and_strengths: { ingredients: [{ ingredient: null, strength: [] }], isExactIngredients: false },
				ma_holder: initialState.ma_holder,
				// searchName: initialState.searchName,
			});

 
			const updatedParams = shouldReset ? resetFields(mergedParams) : mergedParams;

			// Filter out empty null or initial values
			const filteredParams = Object.keys(updatedParams)
				.filter(key => {
					const value = updatedParams[key as keyof T];
					const initialValue = initialState[key as keyof T];

					// Special handling for ingredient and similar fields to avoid removal of meaningful values
					if (key === 'ingredient_and_strengths') {
						// Keep ingredient and strength values if they are present, even if empty
						return value.ingredients.length > 0 && value.ingredients[0].ingredient !== null;
					}

					if (Array.isArray(value)) {
						return value.length > 0 && JSON.stringify(value) !== JSON.stringify(initialValue);
					} else if (typeof value === 'object' && value !== null) {
						return Object.keys(value).length > 0 && JSON.stringify(value) !== JSON.stringify(initialValue);
					} else {
						return value !== '' && value !== null && value !== undefined && value !== initialValue;
					}
				})
				.reduce((obj, key) => {
					obj[key as keyof T] = updatedParams[key as keyof T];
					return obj;
				}, {} as T);

			const params = new URLSearchParams(window.location.search);

			// Set the 's' parameter with the tableId, ensuring it is unique per table
			params.set(tableId ? `s${tableId}` : 's', JSON.stringify(filteredParams));

			window.history.replaceState(null, '', `?${params.toString()}`);
			return { ...prevParams, ...filteredParams };
		});
	};

	const resetParams = () => {
		const params = new URLSearchParams(window.location.search);

		params.delete(tableId ? `s${tableId}` : 's'); // Delete the 's' param specific to this tableId

		window.history.replaceState(null, '', `?${params.toString()}`);
		
		setTimeout(() => {
			setSearchParams({
				...initialState,
				...(initialState.ingredient_and_strengths && {
					ingredient_and_strengths: { ingredients: [{ ingredient: null, strength: [] }], isExactIngredients: false } 
				})
			});
		}, 0);
	};

	return [searchParams, setParams, resetParams];
};

