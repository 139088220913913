import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Box,
	DialogContentText,
	IconButton,
} from '@mui/material';
import { Logo } from 'components/shared/Logo/Logo';
import { COLORS } from 'utils/consts';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';

interface ErrorModalProps {
	open: boolean;
	message: string;
	title?: string;
	onClose: () => void;
	retryBtn?: boolean;
	canClose?: boolean;
	details?: string;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
	open,
	message,
	title = 'Error',
	details,
	retryBtn = true,
	canClose = true,
	onClose,
}) => {
	const navigate = useNavigate();

	const retryHandler = () => navigate(0);

	return (
		<Dialog
			open={open}
			onClose={canClose ? onClose : undefined}
			maxWidth="xs"
			fullWidth
			sx={{
				'& .MuiPaper-root': { alignItems: 'center', padding: '40px 20px 20px' },
			}}
		>
			{canClose && (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
			)}
			<Box maxWidth={'200px'} py={3}>
				<Logo />
			</Box>
			{title && (
				<DialogTitle sx={{ textAlign: 'center', padding: '20px 0 30px' }} variant="h5">
					{title}
				</DialogTitle>
			)}
			<DialogContent sx={{ textAlign: 'center' }}>
				<DialogContentText color={COLORS.gray} dangerouslySetInnerHTML={{ __html: message }} />
				{details && (
					<DialogContentText color={COLORS.secondary} pt={2}>
						{details}
					</DialogContentText>
				)}
			</DialogContent>
			{retryBtn && (
				<DialogActions>
					<Button onClick={retryHandler} color="primary" variant="contained">
						Retry
					</Button>
				</DialogActions>
			)}
		</Dialog>
	);
};
