import React, { useCallback } from 'react';

import { useAuth } from 'react-oidc-context';
import { Grid, Box, Typography } from '@mui/material';

import { BaseButton } from 'components/Buttons';

export const SignIn = () => {
	const auth = useAuth();

	const handleLoginClick = useCallback(() => {
		auth.signinRedirect();
	}, [auth]);

	return (
		<Box>
			<Typography align="left" sx={{ mb: 4 }}>
			PharmaFootpath login is now secured through SSO. Please check your email for the SSO setup details or continue log in via button below.
			</Typography>
			<Grid item xs={12} sx={{ textAlign: 'center' }}>
				<BaseButton onClick={handleLoginClick} label={'Sign In with SSO'} />
			</Grid>
		</Box>
	);
};
