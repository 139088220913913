import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { getUserCompany, getUserMe } from 'services';

import { IUserType, CompanyType } from '../../models/types';
import { UserContext } from './UserContext';
import { useAuth } from 'react-oidc-context';
import { authTokenService } from 'services/Clients/authTokenService';

export const UserContextProvider = ({ children }: React.PropsWithChildren) => {
	const [user, setUser] = useState<IUserType | null>(null);
	const [userCompany, setUserCompany] = useState<CompanyType | null>(null);

	const [userAuthenticating, setUserAuthenticating] = useState<boolean>(false);
	const [appIsDeploying, setAppIsDeploying] = useState<boolean>(false);

	const auth = useAuth();

	const setUserData = useCallback((userData: IUserType | null) => setUser(userData), []);

	const onSigninUser = useCallback(async (token: string): Promise<void> => {
		try {
			const userMeRes = await getUserMe(token);

			if (userMeRes.is_admin) {
				const company = await getUserCompany(userMeRes?.company?.id);
				setUserCompany(company);
			}
			setUser(userMeRes);
			setAppIsDeploying(false);
		} catch (err: any) {
			if (err.response) {
				setAppIsDeploying(true);
			}
		}
	}, []);

	const onLogoutUser = useCallback((): void => {
		authTokenService.logout(() => {
			setUserData(null);
		});
	}, [setUserData]);

	useEffect(() => {
		async function getUser() {
			if (!authTokenService.isAuthenticated()) {
				return;
			}

			const accessToken = authTokenService.getAccessToken();

			if (accessToken) {
				setUserAuthenticating(true);
				await onSigninUser(accessToken);
				setUserAuthenticating(false);
			}
		}
		getUser();
	}, [auth.user, auth.isAuthenticated, onSigninUser]);

	const store = useMemo(
		() => ({
			user,
			userCompany,
			userAuthenticating,
			appIsDeploying,
			setUserData,
			setAppIsDeploying,
			onSigninUser,
			onLogoutUser,
		}),
		[
			user,
			userCompany,
			userAuthenticating,
			appIsDeploying,
			setUserData,
			setAppIsDeploying,
			onSigninUser,
			onLogoutUser,
		]
	);

	return <UserContext.Provider value={store}>{children}</UserContext.Provider>;
};
