import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Fade,
	Grid,
	Skeleton,
	Tooltip,
	Typography,
} from '@mui/material';
import { ContentCopy, DoneAll } from '@mui/icons-material';

import { getNestedValue } from 'utils';
import { capitalizeString } from 'utils/capitalizeString';
import { CustomCardItem } from '../shared';
import { CardItemTypeEnum } from 'models/enums';

interface BaseCardPropsInterface {
  dataItem?: any;
  cardFields?: any[];
  cardTitle?: string;
  loading: boolean;
  copyBrief?: boolean;
}

export const BaseCard: FC<BaseCardPropsInterface> = ({
	dataItem,
	cardTitle,
	loading,
	cardFields,
	copyBrief = false
}) => {
	const [wasCopied, setWasCopied] = useState(false);

	useEffect(()=>{
		if(wasCopied){
			setTimeout(() => {
				setWasCopied(false);
			}, 1500);
		}
	},[wasCopied]);

	const copyToClipboardHandler = useCallback(() => {
		setWasCopied(true);
		return navigator.clipboard.writeText(
			cardFields ? 
				cardFields.filter((e)=>e.isBriefField)
					.map(field => {
						let value;
						if(field.type === CardItemTypeEnum.LIST){
							const list = getNestedValue(dataItem, field.listFieldPath);
							const uniqueValues = Array.from(
								new Map(
									list?.map((val) => {
										const key = getNestedValue(val, field.fieldPath)?.toLowerCase();
										const value = val;
										return [key, value];
									})
								).values()
							);
							value = uniqueValues.map(val=> getNestedValue(val, field.fieldPath)).join(', ');
						}else{
							value = field.capitalize 
								? capitalizeString(getNestedValue(dataItem, field.fieldPath) || '-') 
								: getNestedValue(dataItem, field.fieldPath) || '-';
						}
						return field.key + ' ' + value;
					})
					.join('\n')
				: ''
			
		);
	},[cardFields, dataItem]);

	return (
		<Card elevation={0}>
			{cardTitle && (
				<Fragment>
					<Grid container justifyContent={'space-between'}>
						<CardHeader title={cardTitle} />
					
						{copyBrief && (
							<Box m={2}>
								<Tooltip
									title={wasCopied 
										? 'Copied to clipboard' 
										: `Copy the ${cardFields?.filter( e=>e.isBriefField ).map( item=> item.briefName).join(', ')} to clipboard`
									}
									disableFocusListener
								>
									<Button onClick={copyToClipboardHandler} style={{height: '100%', padding: '5px'}}>
										<Fade 
											in={!wasCopied} 
											timeout={500} 
											style={{position: 'absolute'}}
										>
											<ContentCopy />
										</Fade> 
										<Fade 
											in={wasCopied} 
											timeout={500} 
											style={{position: 'absolute'}}
										>
											<DoneAll color="success" />
										</Fade>
									</Button>
								</Tooltip>
							</Box>
						)}
					</Grid>
					<Box mx={2}>
						<Divider />
					</Box>
				</Fragment>
			)}
			<CardContent>
				<Box m={2}>
					<Grid container columnSpacing={2}>
						{cardFields && cardFields
							.filter(e=>{
								// display field in card only if mandatory field has value - avoids empty labels
								return e.mandatory && dataItem 
									? dataItem[e.mandatory] 
									: e.mandatoryFieldPath && dataItem
										? getNestedValue(dataItem, e.mandatoryFieldPath)
										: true;
							})
							.map((field, index) => (
								<Grid
									item
									xs={12}
									sm={6}
									md={field.col || 6}
									key={index}
									my={1}
								>
									<Box display={'flex'}>
										<Box mr={2}>
											<Typography
												whiteSpace={'nowrap'}
												color={'inherit'}
												fontWeight={600}
												variant={'body1'}
											>
												{field.key}
											</Typography>
										</Box>

										{!loading ? (
											<Box sx={{ flex: 'unset', flexShrink: 1 }}>
												{dataItem && (
													<CustomCardItem dataItem={dataItem} item={field} />
												)}
											</Box>
										) : (
											<Skeleton
												variant='text'
												animation='wave'
												sx={{ fontSize: '1rem', flexGrow: 1 }}
											/>
										)}
									</Box>
								</Grid>
							))}
					</Grid>
				</Box>
			</CardContent>
		</Card>
	);
};
