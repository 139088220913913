import { useEffect } from 'react';
import { useAuth, AuthContextProps } from 'react-oidc-context';
import { authTokenService } from 'services/Clients/authTokenService';

export function useAuthService(): AuthContextProps {
	const auth = useAuth();

	useEffect(() => {
		authTokenService.setAuthInstance(auth);
	}, [auth]);

	return auth;
}
