import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, ListItem, Checkbox, Typography, Grid, InputAdornment } from '@mui/material';
import { visuallyHidden } from '@mui/utils'; 
import { AddRounded } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';

import { BaseCheckbox, MatFormLabel, TextFieldWrapper } from '..';
import { MaterialUISizeEnum } from 'models/enums';
import { useDebounceObject } from 'hooks';
import {
	MEDIUM_BORDER_RADIUS,
	SMALL_VARIANT_FONT_SIZE,
	SMALL_VARIANT_INPUT_HEIGHT,
} from 'utils/consts/themeConsts';
import { COLORS } from 'utils/consts';
import {
	MaterialUIInputSizeType,
	MaterialUIInputVariantType,
} from 'models/types';
import { getIngredients, getStrengths } from 'services';
import { BaseButton } from 'components/Buttons';
import { CloseIcon, SearchIcon } from 'components/Icons';

interface Option {
	name: string;
	id?: string | number;
}

interface ComboInput {
	ingredient: Option | null;
	strength: Option[];
}

interface IngredientAndStrengths {
	ingredients: ComboInput[];
	isExactIngredients: boolean;
}

interface onChangeParams {
	target: { name: string; value: IngredientAndStrengths };
}

interface PaginationMeta {
	next: string | null;
	hasMore: boolean;
	currentPage: number;
}

interface BaseAutoCompleteInputRemoteDataInterface {
	parentValue?: string;
	onChange: (params: onChangeParams) => void;
	onBlur?: (x: any) => void;
	value?: IngredientAndStrengths;
	name: string;
	autoComplete?: string | number;
	variant?: MaterialUIInputVariantType;
	autoWidth?: boolean;
	fullWidth?: boolean;
	error?: boolean;
	helperText?: string;
	sx?: any;
	inputProps?: any;
	size?: MaterialUIInputSizeType;
	innerGridWrapper?: number;
}

const PAGE_SIZE = 100;

// Initial combo value for comparison
const initialComboValue: IngredientAndStrengths = { ingredients: [{ ingredient: null, strength: [] }], isExactIngredients: false };

export const IngredientComboInput: FC<BaseAutoCompleteInputRemoteDataInterface> = ({
	parentValue,
	onChange,
	name,
	fullWidth,
	size = 'small',
	innerGridWrapper = 6,
	value = initialComboValue,
}) => {
	const [combos, setCombos] = useState<ComboInput[]>(value?.ingredients ?? []);
	const [isExactIngredients, setIsExactIngredients] = useState<boolean>(value?.isExactIngredients ?? false);

	const [ingredientOptions, setIngredientOptions] = useState<{ [key: number]: Option[] }>({0: []});
	const [strengthOptions, setStrengthOptions] = useState<{ [key: number]: Option[] }>({0: []});
	
	const [ingredientLoading, setIngredientLoading] = useState(false);
	const [strengthLoading, setStrengthLoading] = useState<{ [key: number]: boolean }>({0: false});
	
	const [searchState, setSearchState] = useState<{ [key: number]: { ingredient: string, strength: string } }>({});
	const [openStates, setOpenStates] = useState<Record<number, { ingredient: boolean, strength: boolean }>>({});
	
	const [strengthPaginationMeta, setStrengthPaginationMeta] = useState<{ [key: number]: PaginationMeta }>({});
	
	const strengthInputRefs = useRef<(HTMLInputElement | null)[]>([]);
	const [prevSearchState, setPrevSearchState] = useState<{ [key: number]: { ingredient: string, strength: string } }>({});
	const isLoadingMoreRef = useRef<{[key: number]: boolean}>({});
	
	const debouncedSearchState = useDebounceObject(searchState, 500);
	const debouncedValue = useDebounceObject(value, 300);
	
	const parentValueRef = useRef(parentValue);

	useEffect(() => {
		parentValueRef.current = parentValue;
	}, [parentValue]);

	const commercial = useMemo(()=>{
		const params = new URLSearchParams(window.location.search);
		const sParam = params.get('s');
		const decodedParams = sParam ? JSON.parse(sParam) : {};
		if(decodedParams.is_commercial_exclude == false){
			return '';
		}else{
			return ':commercial';
		}
	},[window.location.search]);

	useEffect(() => {
		const loadInitialStrengths = async () => {
			if (value?.ingredients?.length > 0) {
				for (let index = 0; index < value.ingredients.length; index++) {
					const combo = value.ingredients[index];
					if (combo.ingredient) {
						setStrengthLoading(prev => ({ ...prev, [index]: true }));
						
						try {
							const data = await getStrengths({
								search: '',
								search_for: `${combo.ingredient.name}${commercial}`,
								page_size: PAGE_SIZE,
								page: 1
							});
							
							const newOptions: Option[] = [
								...new Map(
									[
										...(combo.strength || []),
										...data.results.map(e => ({
											...e,
											name: e.name || e.description
										}))
									].map(item => [item.name, item])
								).values()
							];
							
							setStrengthOptions(prev => ({
								...prev,
								[index]: newOptions
							}));
							
							setStrengthPaginationMeta(prev => ({
								...prev,
								[index]: {
									next: data.next,
									hasMore: !!data.next,
									currentPage: 1
								}
							}));
							
						} catch (err) {
							console.error(`Error loading initial strengths for index ${index}:`, err);
						} finally {
							setStrengthLoading(prev => ({ ...prev, [index]: false }));
						}
					}
				}
			}
		};
		
		loadInitialStrengths();
	}, [commercial]);

	useEffect(() => {
		value.ingredients.forEach((combo, index) => {
			if (combo.ingredient) {
				getStrengthOptions(index, '', `${combo.ingredient.name}${commercial}`);
			}
		});
	}, [commercial]);

	useEffect(() => {
		setCombos(debouncedValue.ingredients);
		setIsExactIngredients(debouncedValue.isExactIngredients);
	}, [JSON.stringify(debouncedValue)]);

	const getStrengthOptions = useCallback(async (index: number, debounceValue?: string, parentValue?: string, page = 1) => {
		try {
			setStrengthLoading(prev => ({ ...prev, [index]: true }));
			
			if (!parentValue) {
				setStrengthLoading(prev => ({ ...prev, [index]: false }));
				return;
			}
			
			if (page === 1 && !debounceValue) {
				setStrengthOptions(prev => ({
					...prev,
					[index]: []
				}));
			}
			
			const data = await getStrengths({
				search: debounceValue || '',
				search_for: parentValue,
				page_size: PAGE_SIZE,
				page: page
			});
			
			if (page > 1 && page <= (strengthPaginationMeta[index]?.currentPage || 0)) {
				setStrengthLoading(prev => ({ ...prev, [index]: false }));
				return;
			}
			
			const newOptions: Option[] = [
				...new Map(
					[
						...(page > 1 ? (strengthOptions[index] || []) : []),
						...(combos[index]?.strength || []),
						...data.results.map(e => ({
							...e,
							name: e.name || e.description
						}))
					].map(item => [item.name, item]) // Map name to item to ensure uniqueness
				).values()
			];
			
			setStrengthOptions(prev => ({
				...prev,
				[index]: newOptions
			}));
			
			setStrengthPaginationMeta(prev => ({
				...prev,
				[index]: {
					next: data.next,
					hasMore: !!data.next,
					currentPage: page
				}
			}));
			
		} catch (err) {
			console.error(`Error fetching strength options for index ${index}:`, err);
		} finally {
			setStrengthLoading(prev => ({ ...prev, [index]: false }));
		}
	}, [combos, commercial]);

	const getIngredientOptions = useCallback(async (debounceVal, index, reason?: string) => {
		try {
			setIngredientLoading(true);
			if(typeof index !== 'undefined') setIngredientOptions(prev=>({...prev, [index]: []})); // for loading state

			const currentParentValue = parentValueRef.current;

			const data = await getIngredients({
				search: debounceVal ? debounceVal : '',
				search_for: currentParentValue,
				page_size: PAGE_SIZE
			});
			const options: Option[] = [];
			options.push(...data.results.map(e => ({
				...e,
				name: e.name || e.description
			})));

			// autopopulate If the first result matches the parentValue
			if (options.length > 0 && options[0].name === currentParentValue && reason === 'autopopulate') {
				// sets first combo ingredient automatically and fetches proper options
				onChange({target: { name, value: {isExactIngredients, ingredients: [{ingredient: options[0], strength: []}]}  }});
				setIngredientOptions(prev=>({...prev, [0]: options}));
				getStrengthOptions(0, '', `${options[0].name}${commercial}`);
			}else{
				setIngredientOptions(prev=>({...prev, [index]: options}));
			}
		} catch (err) {
			console.error('Error fetching ingredient options:', err);
		} finally {
			setIngredientLoading(false);
		}
	}, [value]);

	useEffect(()=>{
		getIngredientOptions('', 0, 'autopopulate');
	},[parentValue]);

	useEffect(() => {
		if (Object.keys(debouncedSearchState).length > 0) {
			Object.keys(debouncedSearchState).forEach((key) => {
				const index = parseInt(key, 10);
				const { ingredient, strength } = debouncedSearchState[index] || {};
	
				if (ingredient !== undefined && ingredient !== prevSearchState[index]?.ingredient) {
					// If the ingredient has changed, call getIngredientOptions
					getIngredientOptions(ingredient, index);
					// Store the new ingredient value
					setPrevSearchState(prev=>({
						...prev,
						[index]: {
							strength: prev[index]?.strength,
							ingredient
						}
					}));
				}
		
				if (strength !== undefined && strength !== prevSearchState[index]?.strength) {
				// If the strength has changed, call getStrengthOptions
					if (combos[index]?.ingredient?.name) {
						getStrengthOptions(index, strength, `${combos[index].ingredient?.name}${commercial}`);
					}
					// Store the new strength value
					setPrevSearchState(prev=>({
						...prev,
						[index]: {
							ingredient: prev[index]?.ingredient,
							strength
						}
					}));
				}
			});
		}
	}, [debouncedSearchState]);

	const handleListboxScroll = (index: number) => (event: React.UIEvent<HTMLUListElement>) => {
		const listboxNode = event.currentTarget;		
		const isNearBottom = 
			listboxNode.scrollTop + listboxNode.clientHeight >= 
			listboxNode.scrollHeight - 100;
		
		if (
			isNearBottom && 
			strengthPaginationMeta[index]?.hasMore && 
			!strengthLoading[index] && 
			!isLoadingMoreRef.current[index] &&
			combos[index]?.ingredient?.name
		) {
			isLoadingMoreRef.current[index] = true;
			
			const nextPage = (strengthPaginationMeta[index]?.currentPage || 1) + 1;
			
			setTimeout(() => {
				getStrengthOptions(
					index, 
					searchState[index]?.strength || '', 
					`${combos[index].ingredient?.name}${commercial}`, 
					nextPage
				).finally(() => {
					isLoadingMoreRef.current[index] = false;
				});
			}, 0);
		}
	};

	const handleComboChange = useCallback((index: number, field: 'ingredient' | 'strength', value: any) => {
		const updatedCombos = [...combos];

		if (field === 'ingredient') {
			updatedCombos[index][field] = value || null; // Set to null if no value
			
			// clears strength values on ingredient change
			updatedCombos[index].strength = [];
			if (!value) {	
				handleClose(index, 'strength');
			} else {
				// Automatically open the strength field if an ingredient is selected
				handleOpen(index, 'strength');
				// filter strength according to applied ingredient
				getStrengthOptions(index, debouncedSearchState[index]?.strength || '', `${value.name}${commercial}`);
			}
		} else if(updatedCombos[index] && typeof updatedCombos[index].strength !== 'undefined') {
			updatedCombos[index].strength = value;
		}
		onChange({ target: { name, value: { isExactIngredients, ingredients: updatedCombos } } });
	}, [JSON.stringify(combos), onChange, commercial, isExactIngredients]);


	const addCombo = useCallback(() => {
		const newCombos = [...combos, { ingredient: null, strength: [] }];
		const newIndex = newCombos.length - 1;
		setStrengthOptions(prev=>({
			...prev,
			[newIndex]: []
		}));
		getIngredientOptions('', newIndex);
		onChange({ target: { name, value: { isExactIngredients, ingredients: newCombos } } });
	}, [combos, isExactIngredients]);

	const removeCombo = useCallback((index: number) => {
		const updatedCombos = combos.filter((_, i) => i !== index);
		onChange({ target: { name, value: { isExactIngredients, ingredients: updatedCombos } } });
	},[combos, isExactIngredients]);

	const handleOpen = (index: number, field: 'ingredient' | 'strength') => {
		setOpenStates(prev => ({
			...prev,
			[index]: { ...prev[index], [field]: true }
		}));

		if (field === 'strength') {
			setTimeout(() => {
				if (strengthInputRefs.current[index]) {
					strengthInputRefs.current[index]?.focus();
				}
			}, 50);
		}
	};
  
	const handleClose = (index: number, field: 'ingredient' | 'strength') => {
		setOpenStates(prev => ({
			...prev,
			[index]: { ...prev[index], [field]: false }
		}));
	};

	const handleSearchChange = (index: number, field: 'ingredient' | 'strength', value: string) => {
		setSearchState((prevState) => ({
			...prevState,
			[index]: {
				...prevState[index],
				[field]: value,
			},
		}));
	};
	const handleSearchInputChange = useCallback((index: number, field: 'ingredient' | 'strength', value: string, reason: string) => {
		if(reason === 'clear'){
			setSearchState((prevState) => ({
				...prevState,
				[index]: {
					...prevState[index],
					[field]: '',
				},
			}));

			if (field === 'ingredient') {
				handleComboChange(index, 'strength', []);
			}
		}else{
			setSearchState((prevState) => ({
				...prevState,
				[index]: {
					...prevState[index],
					[field]: value,
				},
			}));
		}
	},[]);

	const handleIsExactIngredientsChange = useCallback( () => {
		onChange({ target: { name, value: { isExactIngredients: !value.isExactIngredients, ingredients: combos } } });
	}, [combos, isExactIngredients, value]);

	return (
		<Grid container flexDirection={'column'}>
			<Grid container gap={1}>
				{combos?.map((combo, index) => (
					<Grid container columnSpacing={2} key={'combo' + index} alignItems={'flex-end'}>
						<Grid
							item
							xs={10}
							sm={5.5}
							md={innerGridWrapper}
							sx={(theme) => ({
								[theme.breakpoints.down('sm')]: {
									order: 1
								}
							})}
						>
							<MatFormLabel sx={index !== 0 && visuallyHidden}>Active Ingredient</MatFormLabel>
							<Autocomplete
								multiple={false}
								open={!!openStates[index]?.ingredient}
								onOpen={() => handleOpen(index, 'ingredient')}
								onClose={() => handleClose(index, 'ingredient')}
								onBlur={() => handleClose(index, 'ingredient')}
								onKeyDown={(e) => {
									if (e.code === 'Escape') {
										handleClose(index, 'ingredient');
									}
								}}
								loading={ingredientLoading}
								fullWidth={fullWidth}
								size={size}
								inputValue={searchState[index]?.ingredient || ''}
								onInputChange={(e, value, reason) => handleSearchInputChange(index, 'ingredient', value, reason)}
								value={combo.ingredient || null}
								onChange={(e, value) => handleComboChange(index, 'ingredient', value)}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								options={ingredientOptions[index] || []}
								noOptionsText={ingredientLoading ? 'Loading...' : 'No options'}
								getOptionLabel={(option: any) => option?.name || ''}
								filterOptions={(options) => options}
								renderOption={(props, option: any, rest) => (
									<ListItem key={option.name + rest.index} value={option.name} {...props}>
										<Box display="flex" alignItems="center">
											<Checkbox checked={combo.ingredient?.name === option.name} style={{ pointerEvents: 'none' }} />
											<Typography variant="body1" sx={{ fontSize: '14px' }}>
												{option.name}
											</Typography>
										</Box>
									</ListItem>
								)}
								renderInput={(params) => (
									<TextFieldWrapper
										onChange={(e) => handleSearchChange(index, 'ingredient', e.target.value)}
										placeholder={'Search by active ingredient'}
										sx={{
											maxHeight: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_INPUT_HEIGHT : undefined,
											fontSize: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
											borderRadius: MEDIUM_BORDER_RADIUS,
											backgroundColor: COLORS.white
										}}
										{...params}
										InputProps={{
											...params.InputProps,
											startAdornment: (
												<InputAdornment position="start" sx={{ paddingLeft: '15px' }}>
													<SearchIcon />
												</InputAdornment>
											)
										}}
									/>
								)}
								sx={{
									maxHeight: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_INPUT_HEIGHT : undefined,
									fontSize: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
									borderRadius: MEDIUM_BORDER_RADIUS,
									backgroundColor: COLORS.white
								}}
							/>
						</Grid>
						<Grid
							item
							xs={10}
							sm={5.5}
							md={innerGridWrapper}
							sx={(theme) => ({
								[theme.breakpoints.down('sm')]: {
									order: 3
								}
							})}
						>
							<MatFormLabel sx={index !== 0 && visuallyHidden}>Strength</MatFormLabel>
							<Autocomplete
								multiple
								disabled={!combo.ingredient}
								open={!!openStates[index]?.strength}
								openOnFocus
								onOpen={() => {
									handleOpen(index, 'strength');
									if ((!strengthOptions[index] || strengthOptions[index]?.length === 0) && combo.ingredient) {
										getStrengthOptions(index, '', `${combo.ingredient.name}${commercial}`);
									}
								}}
								onClose={() => handleClose(index, 'strength')}
								onBlur={() => handleClose(index, 'strength')}
								onKeyDown={(e) => {
									if (e.code === 'Escape') {
										handleClose(index, 'strength');
									}
								}}
								loading={strengthLoading[index]}
								noOptionsText={strengthLoading[index] ? 'Loading strengths...' : 'No options available'}
								fullWidth={fullWidth}
								size={size}
								inputValue={searchState[index]?.strength || ''}
								onInputChange={(e, value, reason) => handleSearchInputChange(index, 'strength', value, reason)}
								value={combo.strength || []}
								onChange={(e, value) => handleComboChange(index, 'strength', value)}
								options={strengthOptions[index] || []}
								getOptionLabel={(option: any) => option.name}
								ListboxProps={{
									onScroll: handleListboxScroll(index)
								}}
								renderTags={(value) => {
									const limitedTags = value.slice(0, 2); // Limit to 2 tags
									const additionalCount = Math.max(value.length - limitedTags.length, 0);

									return [
										...limitedTags.map((tag, tagIndex) => (
											<Typography
												key={`${tag.id}-${tagIndex}`}
												variant="body2"
												sx={{
													paddingLeft: tagIndex === 0 ? '5px' : 0,
													paddingRight: '5px'
												}}
											>
												{`${tag.name}${tagIndex < 2 ? ',' : ''}`}
											</Typography>
										)),
										additionalCount > 0 && (
											<Typography key="additional" variant="body2" sx={{ paddingLeft: '5px' }}>
												+{additionalCount}
											</Typography>
										)
									];
								}}
								filterOptions={(options) => options}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderOption={(props, option: any, rest) => (
									<ListItem key={option.name + rest.index} value={option.name} {...props}>
										<Box display="flex" alignItems="center">
											<Checkbox
												checked={combo.strength.some((val) => val.name === option.name)}
												style={{ pointerEvents: 'none' }}
											/>
											<Typography variant="body1" sx={{ fontSize: '14px' }}>
												{option.name}
											</Typography>
										</Box>
									</ListItem>
								)}
								renderInput={(params) => (
									<TextFieldWrapper
										inputRef={(el) => (strengthInputRefs.current[index] = el)}
										onChange={(e) => handleSearchChange(index, 'strength', e.target.value)}
										placeholder={
											!combo.ingredient
												? 'Select an ingredient first'
												: combo.strength.length !== 0
													? ''
													: strengthLoading[index]
														? 'Loading strengths...'
														: strengthOptions[index]?.length > 0
															? 'Select strength'
															: 'No strengths available'
										}
										sx={{
											maxHeight: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_INPUT_HEIGHT : undefined,
											fontSize: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
											borderRadius: MEDIUM_BORDER_RADIUS,
											backgroundColor: COLORS.white,
											'& input': {
												color: '#737070',
												'&::placeholder': {
													color: COLORS.placeholderGray
												}
											}
										}}
										{...params}
									/>
								)}
								sx={{
									maxHeight: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_INPUT_HEIGHT : undefined,
									fontSize: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
									borderRadius: MEDIUM_BORDER_RADIUS,
									backgroundColor: COLORS.white
								}}
							/>
						</Grid>
						<Grid
							item
							sx={(theme) => ({
								[theme.breakpoints.down('sm')]: {
									order: 2
								}
							})}
						>
							{combos.length > 1 && (
								<BaseButton
									color="inherit"
									onClick={() => removeCombo(index)}
									sx={{
										background: 'none',
										padding: 0,
										minWidth: 'auto',
										height: 'auto',
										marginBottom: '13px',
										'&:hover': {
											background: 'none'
										}
									}}
								>
									<CloseIcon />
								</BaseButton>
							)}
						</Grid>
					</Grid>
				))}
			</Grid>
			<Grid container alignItems={'center'}>
				{combos.filter((combo) => combo.ingredient).length > 0 && (
					<BaseCheckbox
						onChange={handleIsExactIngredientsChange}
						checked={isExactIngredients}
						name={'exact_ingredients_count'}
						label={'Display products with selected active ingredient(s) only'}
					/>
				)}

				{combos.length < 3 && (
					<Box>
						<BaseButton
							onClick={addCombo}
							startIcon={<AddRounded />}
							fullWidth={false}
							disabled={!combos[combos.length - 1].ingredient} // doesn't allow adding new combo, unless current combo ingredient is filled out
							sx={{
								background: 'none',
								color: COLORS.blue,
								textTransform: 'none',
								'&:hover': {
									background: 'none',
									color: COLORS.blue
								},
								'&.Mui-disabled': {
									background: 'none',
									color: COLORS.blue,
									opacity: 0.5
								}
							}}
						>
							Add ingredient
						</BaseButton>
					</Box>
				)}
			</Grid>
		</Grid>
	);
};