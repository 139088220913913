import axios from 'axios';
import { authTokenService } from 'services/Clients/authTokenService';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
	headers: {
		'content-type': 'multipart/form-data',
	},
});

const transformData = (data) => {
	const formData = new FormData();

	for (const key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			const value = data[key];
			formData.append(key, value !== undefined ? value : '');
		}
	}
	return formData;
};

instance.interceptors.request.use(
	(config) => {
		if (config.headers === undefined) config.headers = new axios.AxiosHeaders();

		const token = authTokenService.getAccessToken();

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		if (config.headers['Content-Type'] === 'multipart/form-data') {
			config.data = transformData(config.data);
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default instance;
