import AuthClient from './Clients/AuthClient';
import { ConfirmMFADeviceAssociationRequest, InitMFADeviceAssociationResponse } from 'models/interfaces';

const mfaServicesUrl = '/auth/users/';

export const initMFADeviceAssociation = async () => {
	const response = await AuthClient.post(`${mfaServicesUrl}init_mfa_device_association/`);
	return response.data as InitMFADeviceAssociationResponse;
};

export const confirmMFADeviceAssociation = async ({ user_code }: ConfirmMFADeviceAssociationRequest) => {
	const response = await AuthClient.post(`${mfaServicesUrl}confirm_mfa_device_association/`, { user_code });
	return response.data;
};
