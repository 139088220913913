/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { ReferenceDataContext } from './ReferenceDataContext';
import { getCountries } from 'services';
import { euCountriesCode, euCountriesId, euCountriesName, SERVER_DATE_FORMAT } from 'utils/consts';
import { useUser } from 'context/UserContext/UserContext';

export interface Country {
	id: number;
	name: string;
	code: string;
}
 
export interface ReferenceDataType {
	countries: Country[];
	euCountries: Country[];
	dateDropdownOptions: { id: any; label: any; }[];
}

export const ReferenceDataProvider = ({ children }) => {
	const { user } = useUser();
	
	const [referenceData, setReferenceData] = useState<ReferenceDataType>({
		countries: [],
		euCountries: [{id: euCountriesId, name: euCountriesName, code: euCountriesCode}],
		dateDropdownOptions: [
			{
				id: moment(
					new Date(new Date().setMonth(new Date().getMonth() - 6))
				).format(SERVER_DATE_FORMAT),
				label: 'Last 6 months',
			},
			{
				id: moment(
					new Date(new Date().setFullYear(new Date().getFullYear() - 1))
				).format(SERVER_DATE_FORMAT),
				label: 'Last 12 months',
			},
			{
				id: moment(
					new Date(new Date().setFullYear(new Date().getFullYear() - 2))
				).format(SERVER_DATE_FORMAT),
				label: 'Last 24 months',
			},
			{ id: null, label: 'All' },
		],
	});
	const [loadingReference, setLoadingReference] = useState(false);

	useEffect(() => {
		if(!user) return; // prevents duplicate request before user fetch
		async function fetchReferenceData() {
			try {
				setLoadingReference(true);
				const countriesData = await getCountries();
				const euCountriesData = await getCountries({ is_eu_country: true });
				setReferenceData((prevData) => ({
					...prevData,
					countries: countriesData.map((e) => ({
						...e,
						name: e.name === 'Czech Republic' ? 'Czechia' : e.name,
					})),
					euCountries: [ 
						...new Map(
							[...referenceData.euCountries, ...euCountriesData.map((e) => ({
								...e,
								name: e.name === 'Czech Republic' ? 'Czechia' : e.name,
							}))].map((obj) => [obj.code, obj]) // Assuming 'code' is unique
						).values(),
					],
				}));
			} finally {
				setLoadingReference(false);
			}
		}
		fetchReferenceData();
	}, [user]);

	const providerValue = useMemo(
		() => ({
			referenceData,
			loadingReference,
		}),
		[referenceData, loadingReference]
	);

	return (
		<ReferenceDataContext.Provider value={providerValue}>
			{children}
		</ReferenceDataContext.Provider>
	);
};
