import React, { useEffect, useMemo, useState } from 'react';
import {
	Avatar,
	Box,
	Button,
	Divider,
	Grid,
	Link,
	Typography,
	styled,
} from '@mui/material';
import { BaseAccordion } from 'components/Accordion/Accordion';
import { BaseButton } from 'components/Buttons';
import { Card } from 'components/Card/Card';
import { RemoteDataTable, SimpleTable } from 'components/Tables';

import { BaseInput } from 'components/Inputs';
import {
	companyNotesTableHeaders,
	parallelTradeLicensesTableHeaders,
} from 'components/Tables/SimpleTable/TableHeaders';
import { useAppData, useNotifications, useUser } from 'context';
import { Formik } from 'formik';
import { addNoteSchema, initialFormValues } from 'forms/addNote';
import { useQueryPagination } from 'hooks';
import {
	AlertsTypesEnum,
	StatusColorEnum,
	TableCellContentTypeEnum,
} from 'models/enums';
import { CompanyType, ICompanyNote, ITradeLicense } from 'models/types';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
	addCompanyNote,
	getCompany,
	getCompanyNotes,
	getParallelTradeLicensesByCompany,
} from 'services';
import { COLORS } from 'utils/consts';
import { msrketingAuthorizationLicensesColumns } from './columns';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';
import { useProducts } from 'services/queries';
import { BaseModal } from 'components/Modals';

const AddNoteHeaderWrapper = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
}));

const AvatarWrapper = styled(Box)({
	display: 'flex',
	gap: '8px',
	alignItems: 'center',
});

const UserAvatar = styled(Avatar)({
	backgroundColor: COLORS.secondary,
	color: COLORS.black,
	width: 48,
	height: 48,
});

const ModalTitle = styled('span')(() => ({
	fontWeight: 600,
	fontSize: '24px',
	lineHeight: 1.5,
}));

const FormWrapper = styled(Box)(() => ({
	width: '100%',
	marginTop: '54px',
}));

const ButtonsWrapper = styled(Box)(() => ({
	width: '100%',
	marginTop: '56px',
	display: 'flex',
	gap: '16px',
	alignItems: 'center',
	justifyContent: 'space-between',
}));

const StyledButton = styled(Button)(() => ({
	padding: '17px',
	borderRadius: MEDIUM_BORDER_RADIUS,
	width: '100%',
}));

const CompanyInfo = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',
	marginBottom: '36px',
	gap: '40px',
}));

const CompanyInfoDataLabel = styled(Box)(() => ({
	display: 'flex',
	gap: '10px',
}));

const CompanyDescription = styled(Typography)(() => ({
	fontSize: '16px',
	lineHeight: 1.5,
	marginTop: '36px',
}));

enum CompanyTablesEnum {
  PARALLEL_LICENSES = 'parallel',
  MARKETING_LICENSES = 'marketing',
  NOTES = 'notes',
}

export const Company = () => {
	const [order, setOrder] = useState<'desc' | 'asc' | undefined>('asc');
	const [orderBy, setOrderBy] = useState('created_at');
	const [showAddNoteModal, setShowAddNoteModal] = useState(false);
	const [companyData, setCompanyData] = useState<CompanyType | null>(null);
	const [companyNotes, setCompanyNotes] = useState<ICompanyNote[] | []>([]);
	const [companyNotesCount, setCompanyNotesCount] = useState(0);
	const [companyNotesPage, setCompanyNotesPage] = useState(1);
	const [tradeLicenses, setTradeLicenses] = useState<any[] | []>([]);
	const [tradeLicensesCount, setTradeLicensesCount] = useState(0);
	const [tradeLicensesPage, setTradeLicensesPage] = useState(1);
	const [orderLicenses, setOrderLicenses] = useState<
    'desc' | 'asc' | undefined
  >('asc');
	const [orderByLicenses, setOrderByLicenses] = useState('product__name');
	const { addNotification } = useNotifications();
	const { user } = useUser();
	const { setHeader, setLoading } = useAppData();
	const { uid } = useParams();

	const userInitials = user
		? user?.first_name?.split('')[0] + user?.last_name?.split('')[0]
		: '';

	useEffect(() => {
		const getData = async () => {
			setHeader('');
			setLoading(true);

			try {
				const response = await getCompany({ params: { id: uid } });

				setCompanyData(response);
				setHeader(response.name);
			} catch (err: any) {
				const message = err?.data?.detail
					? err.data.detail
					: 'There has been an error while loading company';
				addNotification({ message, type: AlertsTypesEnum.ERROR });
			} finally {
				setLoading(false);
			}
		};

		getData();
		getNotes({
			params: { uid },
			queryParams: { ordering: orderBy, page: companyNotesPage },
		});
		getLicenses(uid, orderByLicenses, tradeLicensesPage);
	}, []);

	const getNotes = async ({ params, queryParams }) => {
		try {
			const response = await getCompanyNotes({ params, queryParams });

			const notesData = response?.results.map((noteItem: ICompanyNote) => {
				return {
					name: {
						name: `${noteItem.user?.first_name} ${noteItem.user?.last_name}`,
						type: TableCellContentTypeEnum.AVATAR,
						link: noteItem.user?.avatar,
					},
					description: noteItem.note,
					date: moment(noteItem.created_at).format('Do MMMM YYYY'),
					note: {
						type: 'icon',
					},
				};
			});

			setCompanyNotes(notesData);
			setCompanyNotesCount(response?.count);
		} catch (err: any) {
			const message = err?.data?.detail
				? err.data.detail
				: 'There has been an error while loading company notes';
			addNotification({ message, type: AlertsTypesEnum.ERROR });
		}
	};

	const getLicenses = async (uid: any, ordering: string, page: number) => {
		try {
			const response = await getParallelTradeLicensesByCompany({
				queryParams: { company: uid, ordering, page },
			});

			const licenses = response && response.results.length
				? response.results.map(
					(item: ITradeLicense) => ({
						name: {
							value: item?.product?.name,
							type: TableCellContentTypeEnum.LINK,
							icon: true,
							link: `/product-explorer/${
								// should be product pack id
								item?.eu_authorized_product
									? item?.eu_authorized_product?.product?.id
									: item?.product?.id
							}${
								// should be country id or code
								(!item.eu_authorized_product || !item.eu_authorized_product.product.is_eu_authorized) &&
								`?country=${item?.country || '0'}`
							}`,
						},
						code: item?.eu_authorized_product
							? item?.eu_authorized_product?.eu_code
							: item?.product?.national_registration_number,
						strength: item?.eu_authorized_product 
							? item?.eu_authorized_product?.active_ingredients?.map((val) => val.strength).join(', ')
							: item?.product 
								? item?.product?.active_ingredients?.map((val) => val.strength).join(', ')
								: null,
						pack_size: item?.eu_authorized_product?.pack_size?.name,
						manufacturers: item?.product?.ma_holder?.name,
						number: item?.license_number,
						parallel_trader: item?.company?.name,
						decision_date: item.date_of_decision
							? moment(item.date_of_decision).format('Do MMMM YYYY')
							: '',
						country: item.destination_countries
							? item.destination_countries
								.map((country) => country?.name)
								.join()
							: '',
						status: {
							value: item.status,
							type: TableCellContentTypeEnum.STATUS,
							color: StatusColorEnum[item.status?.toUpperCase()],
						},
					}))
				: [];

			setTradeLicenses(licenses);
			setTradeLicensesCount(response.count);
		} catch (err: any) {
			const message = err?.data?.detail
				? err.data.detail
				: 'There has been an error while loading licenses';
			addNotification({ message, type: AlertsTypesEnum.ERROR });
		}
	};

	const handleChangePage = (event: unknown, newPage: number, type: string) => {
		switch (type) {
			case CompanyTablesEnum.PARALLEL_LICENSES:
				setTradeLicensesPage(newPage + 1);
				getLicenses(uid, orderByLicenses, newPage + 1);
				break;
			case CompanyTablesEnum.MARKETING_LICENSES:
				setTradeLicensesPage(newPage + 1);
				getLicenses(uid, orderByLicenses, newPage + 1);
				break;
			case CompanyTablesEnum.NOTES:
				setCompanyNotesPage(newPage + 1);
				getNotes({
					params: { uid },
					queryParams: { ordering: orderBy, page: newPage + 1 },
				});
				break;
		}
	};

	const handleRequestSort = (property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		const sortDirection = isAsc ? '-' + property : property;

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(sortDirection);
		getNotes({
			params: { uid },
			queryParams: { ordering: sortDirection, page: companyNotesPage },
		});
	};

	const handleRequestSortLicenses = (property: string) => {
		const isAsc = orderByLicenses === property && orderLicenses === 'asc';
		const sortDirection = isAsc ? '-' + property : property;

		setOrderLicenses(isAsc ? 'desc' : 'asc');
		setOrderByLicenses(sortDirection);
		getLicenses(uid, sortDirection, tradeLicensesPage);
	};

	const handleCloseModal = () => {
		setShowAddNoteModal(false);
	};

	const onAddNewNoteHandler = async (values: any, { setSubmitting }: any) => {
		try {
			await addCompanyNote(uid, {
				note: values.note,
				user: user,
			}).then(async () => {
				await getNotes({
					params: { uid },
					queryParams: { ordering: orderBy, page: companyNotesPage },
				});
			});

			addNotification({
				message: 'Note has been added',
				type: AlertsTypesEnum.SUCCESS,
			});
		} catch (err: any) {
			const message = err?.data?.detail
				? err.data.detail
				: 'There has been an error';
			addNotification({ message, type: AlertsTypesEnum.ERROR });
		} finally {
			setSubmitting(false);
			setShowAddNoteModal(false);
		}
	};

	const productQueryParams = useMemo(() => ({ company: uid }), [uid]);
	const {
		data: prodData,
		state: prodState,
		setPagination: prodSetPagination,
		rowCount: prodRowCount,
		pageCount: prodPageCount,
		setPageCount: prodSetPageCount,
	} = useQueryPagination(useProducts, null, null, productQueryParams, {
		mandatory: ['company'],
	});

	return (
		<>
			<Grid container spacing={4}>
				{companyData && (
					<Grid item xs={12}>
						<Card>
							<Box>
								<CompanyInfo>
									<CompanyInfoDataLabel>
										<Typography>
											<strong>License Holder:</strong>
										</Typography>
										<Typography color='primary'>
											{companyData?.licenseHolder}
										</Typography>
									</CompanyInfoDataLabel>
									<CompanyInfoDataLabel>
										<Typography>
											<strong>Website:</strong>
										</Typography>
										<Link href={companyData?.website} target='_blank'>
											{companyData?.website}
										</Link>
									</CompanyInfoDataLabel>
									<CompanyInfoDataLabel>
										<Typography>
											<strong>Address:</strong>
										</Typography>
										<Typography color='primary'>
											{companyData?.address}
										</Typography>
									</CompanyInfoDataLabel>
									<CompanyInfoDataLabel>
										<Typography>
											<strong>Contact:</strong>
										</Typography>
										<Link href={companyData?.contact} target='_blank'>
											{companyData?.contact}
										</Link>
									</CompanyInfoDataLabel>
								</CompanyInfo>
								<Divider />
								<CompanyDescription>
									{companyData?.description}
								</CompanyDescription>
							</Box>
						</Card>
					</Grid>
				)}
				<Grid item xs={12}>
					<BaseAccordion
						title={`Parallel Import licenses (${tradeLicensesCount})`}
					>
						<Box mt={2}>
							<SimpleTable
								order={orderLicenses}
								orderBy={orderByLicenses}
								headCells={parallelTradeLicensesTableHeaders}
								tableRows={tradeLicenses}
								rowsPerPage={10}
								page={tradeLicensesPage - 1}
								itemsCount={tradeLicensesCount}
								onRequestSort={handleRequestSortLicenses}
								handleChangePage={(event, page) =>
									handleChangePage(
										event,
										page,
										CompanyTablesEnum.PARALLEL_LICENSES
									)
								}
							/>
						</Box>
					</BaseAccordion>
				</Grid>
				<Grid item xs={12}>
					<BaseAccordion
						title={`Marketing Authorization licenses (${prodRowCount})`}
					>
						<RemoteDataTable
							state={prodState}
							data={prodData}
							pageCount={prodPageCount}
							onRowsPerPageChange={prodSetPageCount}
							onPaginationChange={prodSetPagination}
							rowCount={prodRowCount}
							columns={msrketingAuthorizationLicensesColumns}
							enableRowSelection={false}
							enableFullScreenToggle
							enablePagination
							lastColumnAlignRight
						/>
					</BaseAccordion>
				</Grid>
				<Grid item xs={12}>
					<BaseAccordion title={`Notes (${companyNotesCount})`}>
						<Box>
							<BaseButton
								type='button'
								variant={'contained'}
								label={'ADD NEW NOTE'}
								onClick={() => setShowAddNoteModal(true)}
								sx={{
									borderRadius: MEDIUM_BORDER_RADIUS,
									padding: '9px 16px',
									width: 'max-content',
									position: 'absolute',
									top: '18px',
									right: '72px',
								}}
							/>
						</Box>
						{companyNotes?.length > 0 && (
							<Box mt={2}>
								<SimpleTable
									order={order}
									orderBy={orderBy}
									headCells={companyNotesTableHeaders}
									tableRows={companyNotes}
									rowsPerPage={10}
									page={companyNotesPage - 1}
									itemsCount={companyNotesCount}
									onRequestSort={handleRequestSort}
									handleChangePage={(event, page) =>
										handleChangePage(event, page, CompanyTablesEnum.NOTES)
									}
								/>
							</Box>
						)}
					</BaseAccordion>
				</Grid>
			</Grid>
			<BaseModal show={showAddNoteModal} close={handleCloseModal} width={600}>
				<AddNoteHeaderWrapper>
					<ModalTitle>Add Note</ModalTitle>
					<AvatarWrapper>
						{user?.avatar ? (
							<UserAvatar alt='Profile image' src={user.avatar} />
						) : (
							<UserAvatar>{userInitials}</UserAvatar>
						)}
						<Typography color='primary' fontSize={12}>
							<strong>
								{user?.first_name} {user?.last_name}
							</strong>
						</Typography>
					</AvatarWrapper>
				</AddNoteHeaderWrapper>
				<FormWrapper>
					<Formik
						initialValues={initialFormValues}
						validationSchema={addNoteSchema}
						onSubmit={onAddNewNoteHandler}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<form onSubmit={handleSubmit}>
								<BaseInput
									fullWidth
									multiline
									rows={6}
									name='note'
									label='Add Note'
									placeholder='Enter your Note here.'
									value={values.note}
									onChange={handleChange}
									onBlur={handleBlur}
									inputProps={{ sx: { backgroundColor: COLORS.background } }}
									error={touched.note && Boolean(errors.note)}
									helperText={touched.note ? errors.note : ''}
								/>
								<ButtonsWrapper>
									<StyledButton
										disableElevation={true}
										color='primary'
										variant='outlined'
										onClick={() => setShowAddNoteModal(false)}
									>
                    CANCEL
									</StyledButton>
									<StyledButton
										disabled={isSubmitting}
										disableElevation={true}
										color='primary'
										variant='contained'
										type='submit'
									>
                    CONFIRM
									</StyledButton>
								</ButtonsWrapper>
							</form>
						)}
					</Formik>
				</FormWrapper>
			</BaseModal>
		</>
	);
};
