import { createContext, useContext } from 'react';

export interface Country {
	id: number;
	name: string;
	code: string;
}

export interface ReferenceDataType {
	countries: Country[];
	euCountries: Country[];
	dateDropdownOptions: { id: any; label: any; }[];
}

interface ReferenceDataContextType {
	referenceData: ReferenceDataType;
	loadingReference: boolean;
 }
export const ReferenceDataContext = createContext<ReferenceDataContextType>({ 
	referenceData: { countries: [], euCountries: [], dateDropdownOptions: [] }, 
	loadingReference: false 
});


export function useReferenceData() {
	const data = useContext(ReferenceDataContext);
	return { ...data };
}
