import React from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Grid, Box, Typography, Link } from '@mui/material';
import { Formik } from 'formik';

import { BaseButton } from 'components/Buttons';
import { BasePasswordInput } from 'components/Inputs';
import { changePasswordSchema, initialFormValues } from 'forms/changePassword';
import { changePassword } from 'services';
import { AlertsTypesEnum, ApplicationRoutesEnum } from 'models/enums';
import { useNotifications } from 'context';
import { COLORS } from 'utils/consts';

const EXPIRE_TOKEN_ERROR = 'expire_token_error';

export const ChangePassword = () => {
	const { addNotification } = useNotifications();
	const navigate = useNavigate();
	const { uid, token } = useParams();

	const onChangePasswordHandler = async (changePasswordInputs: any, { setSubmitting, setErrors }: any) => {
		try {
			const changePasswordDTO = {
				uid,
				token,
				new_password: changePasswordInputs.new_password
			};
			await changePassword(changePasswordDTO);

			navigate(ApplicationRoutesEnum.SIGNIN);
		} catch (err: any) {
			if (err.data.token[0] === 'Invalid token for given user.') {
				addNotification({
					type: AlertsTypesEnum.ERROR,
					message: 'The link has expired.'
				});
				setErrors({ token: EXPIRE_TOKEN_ERROR });
			} else if (err.data.detail) {
				addNotification({ type: AlertsTypesEnum.ERROR, message: err.data.detail });
			} else {
				setErrors(err.data);
			}
		} finally {
			setSubmitting(false);
		}
	};
	return (
		<Box px={4} py={8} display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={1}>
			<Box
				sx={{
					width: { xs: '100%', md: '60%' }
				}}
			>
				<Typography variant="h5" align="left" sx={{ mb: 4 }}>
					Change Your Password
				</Typography>
				<Formik
					initialValues={initialFormValues}
					validationSchema={changePasswordSchema}
					onSubmit={onChangePasswordHandler}
				>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<BasePasswordInput
										fullWidth
										name="new_password"
										label="Enter Password"
										autoComplete="current-password"
										value={values.new_password}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.new_password && Boolean(errors.new_password)}
										helperText={touched.new_password ? errors.new_password : ''}
									/>
								</Grid>
								<Grid item xs={12}>
									<BasePasswordInput
										fullWidth
										name="confirm_password"
										label="Confirm Password"
										autoComplete="current-password"
										value={values.confirm_password}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.confirm_password && Boolean(errors.confirm_password)}
										helperText={touched.confirm_password ? errors.confirm_password : ''}
									/>
								</Grid>
								{errors.token && errors.token === EXPIRE_TOKEN_ERROR ? (
									<Grid item xs={12}>
										<Typography variant="body2" color={COLORS.formError}>The link has expired</Typography>
										<Link variant="body2" component={RouterLink} to={ApplicationRoutesEnum.FORGOT_PASSWORD}>
											Try resetting your password again
										</Link>
									</Grid>
								) : (
									<Grid item xs={12} sx={{ textAlign: 'center' }}>
										<BaseButton disabled={isSubmitting} label={'Submit'} />
									</Grid>
								)}
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</Box>
	);
};