export const upgradeAccountMessage = 'Upgrade account for access';

export const throttling_error_message = {
	message: `<div style="text-align: justify;">We've detected some unusual activity on your account and, as a precaution, we've temporarily restricted access to protect your security. Our team is reviewing the situation, and we’ll get back to you as soon as possible. If you believe this was a mistake or need assistance, please contact our support team at <a href="mailto:hello@pharmafootpath.com?subject=Account%20Access%20Restriction%20-%20Support%20Request">hello@pharmafootpath.com</a>. Thank you for your patience and understanding. Your security is our priority!
	<br>
	Best regards,
	<br>
	PharmaFootpath Team.</div>`,
	withRetry: false,
	canClose: false,
};

export const server_deploying_message = {
	title: 'Server maintenance',
	message: 'Server maintenance underway. Back in 5 minutes. Thank you!',
};

export const network_error_message = {
	title: 'Network error.',
	message: 'Network error.',
};
