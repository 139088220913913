
import React, { useState, useEffect, useMemo, Fragment, useCallback } from 'react';
import moment from 'moment';
import { Typography, Grid, Divider, Alert, LinearProgress } from '@mui/material';
import { 
	COLORS, 
	// COMMON_DATE_FORMAT 
} from 'utils/consts';
import { useNotifications } from 'context';
import { 
	useDashboardStats, 
	// useQueryPagination
} from 'hooks';
import {
	AlertsTypesEnum,
	ContentTypeEnum,
} from 'models/enums';
import {
	getNotes,
	getShortages,
} from 'services';
import { Card } from 'components/Card/Card';
import { InfoCard } from 'components/Cards';
import { Link } from 'react-router-dom';
import { Masonry } from '@mui/lab';
import { StyledInlineLink } from 'components/StyledComponents';
import {
	ArrowBottomIcon,
	CalendarIcon,
	LinkArrowSquareIcon,
} from 'components/Icons';
import { 
	BaseIconButton, 
	// ExpandButton
} from 'components/Buttons';
import { SingleLineChips } from 'components/Lists';
import { RocketLaunch } from '@mui/icons-material';
import { dateFormatter } from 'utils';
// import { useDashboardPrices, useProductNotes } from 'services/queries';
// import { pricesColumns } from './columns';
// import { RemoteDataTable } from 'components/Tables';

export const Home = () => {
	const { addNotification } = useNotifications();
	
	const [notes, setNotes] = useState<any>(new Map());
	const [isNotesLoading, setIsNotesLoading] = useState(true);
	const [moreNotesAvailable, setMoreNotesAvailable] = useState(false);
	const [notesPage, setNotesPage] = useState(1);

	const [isShortagesLoading, setIsShortagesLoading] = useState(true);
	const [shortages, setShortages] = useState<any>(new Map());
	const [moreShortagesAvailable, setMoreShortagesAvailable] = useState(false);
	const [shortagesPage, setShortagesPage] = useState(1);

	const {
		stats,
		dates,
		loading: isStatsLoading
	} = useDashboardStats();

	// const pricesParams = useMemo(
	// 	() => ({
	// 		change_delta: 20
	// 	}), []
	// );

	// const {
	// 	data: pricesData,
	// 	state: pricesState,
	// 	setPagination: pricesSetPagination,
	// 	rowCount: pricesRowCount,
	// 	pageCount: pricesPageCount,
	// 	setPageCount: pricesSetPageCount,
	// } = useQueryPagination(useDashboardPrices, null, null, pricesParams);

	const isShortagesComingSoon = useMemo(() => false, []);

	const groupBy = (list, keyGetter) => {
		const map = new Map();
		list.forEach((item: any) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	};

	const getDashboardNotes = useCallback(async (page: number) => {
		try {
			const response = await getNotes({
				queryParams: {
					ordering: '-created_at',
					page,
					page_size: 5,
				},
			});
			const notes = response?.results;
			const noteGroups = groupBy(notes, (note) => note.created_at);
			const noteGroupsSorted = new Map(
				[...noteGroups.entries()].sort().reverse()
			);
			setNotes(noteGroupsSorted);
			setMoreNotesAvailable(response?.next);
		} catch (err: any) {
			const message = err?.data?.detail
				? err.data.detail
				: 'There has been an error while loading company notes';
			addNotification({ message, type: AlertsTypesEnum.ERROR });
		} finally {
			setIsNotesLoading(false);
		}
	}, [addNotification]);

	const getDashboardShortages = useCallback(async (page: number) => {
		setIsShortagesLoading(true);
		try {
			const response = await getShortages({
				queryParams: {
					ordering: '-notification_date',
					page,
					page_size: 5,
				},
			});
			const shortages = response?.results;
			const shortageGroups = groupBy(
				shortages,
				(shortage) => shortage.notification_date
			);
			const shortageGroupsSorted = new Map(
				[...shortageGroups.entries()].sort().reverse()
			);
			setShortages(shortageGroupsSorted);
			setMoreShortagesAvailable(response?.next);
		} catch (err: any) {
			const message = err?.data?.detail
				? err.data.detail
				: 'There has been an error while loading shortages';
			addNotification({ message, type: AlertsTypesEnum.ERROR });
		} finally {
			setIsShortagesLoading(false);
		}
	}, [addNotification]);

	useEffect(() => {
		// getDashboardNotes(notesPage);
		getDashboardNotes(1);
		if (!isShortagesComingSoon) {
			getDashboardShortages(1);
		}
	}, [getDashboardNotes, getDashboardShortages, isShortagesComingSoon]);

	const loadMoreHandler = (page: number) => {
		const newPage = page + 1;
		setNotesPage(newPage);
		expandNotesHandler(newPage);
	};

	const loadMoreShortagesHandler = (page: number) => {
		const newPage = page + 1;
		setShortagesPage(newPage);
		expandShortagesHandler(newPage);
	};

	const expandShortagesHandler = useCallback(async (page: number) => {
		try {
			const response = await getShortages({
				queryParams: {
					ordering: '-notification_date',
					page,
					page_size: 5,
				},
			});
			const fetchedShortages = response?.results;
			const shortageGroups = groupBy(
				fetchedShortages,
				(shortage) => shortage.notification_date
			);
			const shortageGroupsSorted = new Map(
				[...shortageGroups.entries()].sort().reverse()
			);

			const clonedShortages = new Map<any, any>([...shortages]);

			// Update the cloned state with new content
			shortageGroupsSorted.forEach((value, key) => {
				if (clonedShortages.has(key)) {
					// If the key already exists, append the new values
					clonedShortages.set(key, clonedShortages.get(key).concat([...value]));
				} else {
					// If the key is new, create a new entry
					clonedShortages.set(key, [...value]);
				}
			});

			// const result = new Map<any, any>([...shortages]);
			// const addition = new Map<any, any>([...shortageGroupsSorted]);

			// addition.forEach((value, key) => {
			//   if (result.has(key)) {
			//     result.set(key, result.get(key).concat(value));
			//   } else {
			//     result.set(key, value);
			//   }
			// });

			setShortages(clonedShortages);
			setMoreShortagesAvailable(response?.next);
		} catch (err: any) {
			const message = err?.data?.detail
				? err.data.detail
				: 'There has been an error while loading shortages';
			addNotification({ message, type: AlertsTypesEnum.ERROR });
		}
	}, [addNotification, shortages]);

	const expandNotesHandler = async (page: number) => {
		try {
			const response = await getNotes({
				queryParams: {
					ordering: '-created_at',
					page,
					page_size: 5,
				},
			});
			const fetchedNotes = response?.results;
			const noteGroups = groupBy(fetchedNotes, (note) => note.created_at);
			const noteGroupsSorted = new Map(
				[...noteGroups.entries()].sort().reverse()
			);

			const result = new Map<any, any>([...notes]);
			const addition = new Map<any, any>([...noteGroupsSorted]);

			addition.forEach((value, key) => {
				if (result.has(key)) {
					result.set(key, result.get(key).concat(value));
				} else {
					result.set(key, value);
				}
			});

			setNotes(result);
			setMoreNotesAvailable(response?.next);
		} catch (err: any) {
			const message = err?.data?.detail
				? err.data.detail
				: 'There has been an error while loading company notes';
			addNotification({ message, type: AlertsTypesEnum.ERROR });
		}
	};

	const createContentTypeLink = (contentType: string, contentObject: any) => {
		switch (contentType) {
			case ContentTypeEnum.PRODUCT:
				return `/product-explorer/${contentObject?.pack?.id}/${!contentObject?.is_eu_registered && contentObject?.country
					? `?country=${contentObject?.country?.id}`
					: ''}`;
			case ContentTypeEnum.PRODUCT_REPRESENTATION:
				return `/product-explorer/${contentObject?.pack?.id}/${!contentObject?.is_eu_registered &&
					`?country=${contentObject?.country?.id || '0'}`}`;
			case ContentTypeEnum.COMPANY:
				return `/company-explorer/${contentObject?.id}`;
			case ContentTypeEnum.MA_HOLDER:
				return `/company-explorer/${contentObject?.id}`; // TODO: CHECK WHERE THIS LINKS TO
			case ContentTypeEnum.ACTIVE_INGREDIENT:
				return `/ingredients/${contentObject?.id}`;
		}
	};

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography
					color={COLORS.black}
					fontWeight={600}
					variant={'h2'}
					fontSize={15}
				>
					Week commencing {dates.start.format('Do MMMM')}
				</Typography>
				<Grid container gap={3} sx={{ marginTop: '20px' }}>
					{stats?.map(item => (
						<InfoCard
							key={item.label}
							number={item.number}
							color={COLORS.blue}
							label={item.label}
							labelProps={{ sx: { maxWidth: item.max_width } }}
							loading={isStatsLoading}
						/>
					))}
				</Grid>
			</Grid>
			<Grid item container xs={12} flexWrap='nowrap'>
				<Grid item xs={6}>
					<Grid container justifyContent={'space-between'}>
						<Typography
							color={COLORS.black}
							fontWeight={600}
							variant={'h2'}
							fontSize={24}
						>
							Shortage Announcements
						</Typography>
						{!isShortagesComingSoon && (
							<Link
								to='/shortages'
								style={{ color: COLORS.blue, textUnderlineOffset: '3px' }}
							>
								View Shortages
							</Link>
						)}
					</Grid>
					{isShortagesLoading && !isShortagesComingSoon ? (
						<LinearProgress sx={{ marginTop: '20px' }} />
					) : !isShortagesComingSoon ? (
						shortages?.keys ?
							[...shortages.keys()].map((date) => (
								<Fragment key={date}>
									<Grid container sx={{ margin: '20px 0' }}>
										<CalendarIcon style={{ marginRight: '15px' }} />
										<Typography fontWeight={300} variant={'subtitle2'}>
											{moment(date).isSame(new Date(), 'day')
												? 'Today'
												: dateFormatter(date)}
										</Typography>
										<Divider
											flexItem
											sx={{ flex: 1, margin: '10px 0 10px 10px' }}
										/>
									</Grid>
									<Masonry columns={2} spacing={2} sx={{ marginTop: '20px' }}>
										{shortages?.get(date)?.map((item) => (
											<Card key={item?.id + item?.notification_date + item?.expected_end_date}>
												{item?.registration?.pack?.product?.name ? (
													<StyledInlineLink
														to={`${createContentTypeLink('productregistration', {
															pack: { id: item?.registration?.pack?.id },
															country: item?.registration?.country,
															is_eu_registered: item?.registration?.is_eu_registered
														})}`}
														style={{ width: '100%' }}
													>
														<Typography
															component='h3'
															fontSize={18}
															fontWeight={500}
															sx={{
																width: 'calc(100% - 25px)',
																lineHeight: '1.3em',
																maxHeight: '1.3em',
																overflow: 'hidden',
																textOverflow: 'ellipsis',
																whiteSpace: 'nowrap',
																paddingRight: '8px',
															}}
														>
															{item?.registration?.pack?.product?.name}
														</Typography>
														<LinkArrowSquareIcon />
													</StyledInlineLink>
												) : (
													<Typography
														component='h3'
														fontSize={18}
														fontWeight={500}
														sx={{
															width: 'calc(100% - 25px)',
															lineHeight: '1.3em',
															maxHeight: '1.3em',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															whiteSpace: 'nowrap',
															paddingRight: '8px',
														}}
													>
														{item?.name}
													</Typography>
												)}
												{item?.registration?.pack?.active_ingredients?.length > 0 ? (
													<SingleLineChips
														data={item.registration.pack.active_ingredients.map(e => ({
															id: e.active_ingredient?.id,
															name: e.active_ingredient?.name
														}))}
													/>
												) : null}
												<Grid
													container
													alignItems={'center'}
													columnGap={1}
													sx={{ padding: '10px 0' }}
												>
													<img
														loading='lazy'
														width='20'
														height='15'
														src={`https://flagcdn.com/w20/${item?.country?.code?.toLowerCase()}.png`}
														srcSet={`https://flagcdn.com/w40/${item?.country?.code?.toLowerCase()}.png 2x`}
														style={{ borderRadius: '2px' }}
														alt={`Flag of ${item?.country?.name}`}
													/>
													<Typography variant='body2'>
														{item?.country?.name}
													</Typography>
												</Grid>
											</Card>
										))}
									</Masonry>
								</Fragment>
							)) : (
								<Alert severity='info' sx={{ marginTop: '20px' }}>
									No shortage announcements found.
								</Alert>
							)
					) : (
						<Grid
							container
							justifyContent='center'
							alignItems={'center'}
							flexDirection='column'
							sx={{
								height: '90%',
								background: 'rgba(255,255,255,.4)',
								boxShadow: '1px 1px 15px rgba(0,0,0,.04)',
								marginTop: '20px',
								borderRadius: '10px',
							}}
						>
							<Typography
								variant='subtitle2'
								sx={{ fontSize: '1.5rem', color: COLORS.darkGray }}
							>
								Coming Soon...
							</Typography>
							<RocketLaunch color='primary' fontSize='large' />
						</Grid>
					)}
					{moreShortagesAvailable && (
						<Grid container justifyContent={'center'} pt={2}>
							<BaseIconButton
								onClick={() => loadMoreShortagesHandler(shortagesPage)}
								sx={{ alignSelf: 'center' }}
							>
								<Typography
									sx={{
										textDecoration: 'underline',
										fontWeight: 500,
										textUnderlineOffset: '2px',
									}}
								>
									View More
								</Typography>
								<ArrowBottomIcon style={{ marginLeft: '5px' }} />
							</BaseIconButton>
						</Grid>
					)}
				</Grid>
				<Divider
					orientation='vertical'
					flexItem
					sx={{ margin: '20px 20px 0' }}
				/>
				<Grid item xs={6} sx={{ minHeight: '40vh' }}>
					<Typography
						color={COLORS.black}
						fontWeight={600}
						variant={'h2'}
						fontSize={24}
					>
						Notes
					</Typography>
					{isNotesLoading ? (
						<LinearProgress sx={{ marginTop: '20px' }} />
					) : notes.size === 0 ? (
						<Alert severity='info' sx={{ marginTop: '20px' }}>
							No one has added a note in your company yet, but when they do,
							you&apos;ll see them here!
						</Alert>
					) : (
						notes.keys && [...notes.keys()].map((date) => (
							<Fragment key={date}>
								<Grid container sx={{ margin: '20px 0' }}>
									<CalendarIcon style={{ marginRight: '15px' }} />
									<Typography fontWeight={300} variant={'subtitle2'}>
										{moment(date).isSame(new Date(), 'day')
											? 'Today'
											: dateFormatter(date)}
									</Typography>
									<Divider
										flexItem
										sx={{ flex: 1, margin: '10px 0 10px 10px' }}
									/>
								</Grid>
								<Masonry columns={2} spacing={2} sx={{ marginTop: '20px' }}>
									{notes.get(date).map((item) => (
										<Card key={item.id}>
											<StyledInlineLink
												to={`${createContentTypeLink(
													item.content_type_model,
													item.content_object
												)}`}
												style={{ width: '100%' }}
											>
												<Typography
													component='h3'
													fontSize={18}
													fontWeight={500}
													sx={{
														maxWidth: 'calc(100% - 25px)',
														lineHeight: '1.3em',
														maxHeight: '1.3em',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
														paddingRight: '8px',
													}}
												>
													{item?.content_type_model === ContentTypeEnum.ACTIVE_INGREDIENT
														? item.content_object?.name
														: item.content_object?.pack?.product?.name}
												</Typography>
												<LinkArrowSquareIcon />
											</StyledInlineLink>
											<Typography
												variant='subtitle2'
												component='strong'
												sx={{
													display: 'block',
													padding: '10px 0',
													textTransform: 'capitalize',
												}}
											>
												{item?.user_name || `${item?.user?.first_name} ${item?.user?.last_name}`}
											</Typography>
											<Typography sx={{
												background: 'rgba(207, 204, 214, 0.3)',
												borderRadius: '5px',
												padding: '5px 10px',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}>
												{item?.note}
											</Typography>
										</Card>
									))}
								</Masonry>
							</Fragment>
						))
					)}
					{moreNotesAvailable && (
						<Grid container justifyContent={'center'} pt={2}>
							<BaseIconButton
								onClick={() => loadMoreHandler(notesPage)}
								sx={{ alignSelf: 'center' }}
							>
								<Typography
									sx={{
										textDecoration: 'underline',
										fontWeight: 500,
										textUnderlineOffset: '2px',
									}}
								>
									View More
								</Typography>
								<ArrowBottomIcon style={{ marginLeft: '5px' }} />
							</BaseIconButton>
						</Grid>
					)}
				</Grid>
			</Grid>
			{/* <Grid item xs={12}>
				<RemoteDataTable
					state={pricesState}
					data={pricesData}
					pageCount={pricesPageCount}
					onRowsPerPageChange={pricesSetPageCount}
					onPaginationChange={pricesSetPagination}
					rowCount={pricesRowCount}
					columns={pricesColumns}
					enableRowSelection={false}
					enablePagination
					lastColumnAlignRight
					renderCustomActions={({table})=>(
						<Grid container justifyContent={'space-between'}>
							<Typography
								color={COLORS.black}
								fontWeight={600}
								variant={'h2'}
								fontSize={24}
								my={2}
							>
								Price changes
							</Typography>
							<ExpandButton 
								isExpanded={table.getState().isFullScreen}
								onClick={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
							/>
						</Grid>
					)}
				/>
			</Grid> */}
		</Grid>
	);
};
