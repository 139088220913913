import React, { FC, ReactNode, useEffect, useState, useRef } from 'react';

import { Box, ListItem, Checkbox, Typography, CircularProgress, ListItemButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { MatFormLabel, TextFieldWrapper } from '..';
import { MaterialUISizeEnum } from 'models/enums';
import {
	MEDIUM_BORDER_RADIUS,
	SMALL_VARIANT_FONT_SIZE,
	SMALL_VARIANT_INPUT_HEIGHT,
} from 'utils/consts/themeConsts';
import { COLORS } from 'utils/consts';
import {
	MaterialUIInputSizeType,
	MaterialUIInputVariantType,
} from 'models/types';
import { useDebounce } from 'hooks';

const InjectablesLabel = 'Injectables';

interface BaseAutoCompleteInputRemoteDataInterface {
	parentValue?: string;
	filterLabel?: string;
	onChange?: (x: any) => void;
	onBlur?: (x: any) => void;
	value?: any;
	name: string;
	label: string;
	labelInfo?: ReactNode;
	placeholder?: string;
	autoComplete?: string | number;
	variant?: MaterialUIInputVariantType;
	autoWidth?: boolean;
	fullWidth?: boolean;
	error?: boolean;
	helperText?: string;
	sx?: any;
	inputProps?: any;
	size?: MaterialUIInputSizeType;
	dataService?: (x: any) => Promise<any>;
}

interface Option {
	name: string;
	id?: string | number;
}

export const BaseAutoCompleteInputRemoteData: FC<BaseAutoCompleteInputRemoteDataInterface> = ({
	parentValue,
	filterLabel,
	value,
	onChange,
	label,
	labelInfo,
	placeholder,
	name,
	fullWidth,
	size = 'small',
	dataService,
}) => {
	const [inputOptions, setInputOptions] = useState<Option[]>([]);
	const [search, setSearch] = useState('');
	const debounceValue = useDebounce(search);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isInjectable, setIsInjectable] = useState<boolean | null>(null);
	const [page, setPage] = useState(1);
	const [nextUrl, setNextUrl] = useState<boolean>(true);
	const [injectablesAdded, setInjectablesAdded] = useState<boolean>(false);
	const listboxRef = useRef<HTMLUListElement>(null);

	useEffect(() => {
		async function getData(debounceValue, page) {
			try {
				setLoading(true);
				const data = dataService && (await dataService({
					[filterLabel || 'search_for']: parentValue,
					search: debounceValue,
					page_size: 10,
					page,
					...(isInjectable !== null && { is_injectable: isInjectable })
				}));
				const options: Option[] = [];
				if (name === 'presentation' && !injectablesAdded && InjectablesLabel.toLocaleLowerCase().includes(debounceValue.toLowerCase())) {
					options.push({
						name: InjectablesLabel
					});
					setInjectablesAdded(true);
				}
				options.push(...data.results.map(e => ({
					...e,
					name: e.name || e.description
				})));
				setInputOptions(prevOptions => page === 1 ? options : [...prevOptions, ...options]);
				setNextUrl(!!data.next);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}
		getData(debounceValue, page);
	}, [debounceValue, dataService, parentValue, isInjectable, page]);

	useEffect(() => {
		setPage(1);
		setInputOptions([]);
		setNextUrl(true);
		setInjectablesAdded(false);
	}, [debounceValue]);

	const onChangeHandler = (e, selectedValues, reason) => {
		// Get the last selected item (the one just clicked)
		let lastSelected;
		if (reason === 'removeOption') { 
			// Find the removed item by checking which item is missing in selectedValues
			lastSelected = value.find((option) => !selectedValues.some((selected) => selected.name === option.name));
		} else {
			// Default: Get the last selected item
			lastSelected = selectedValues[selectedValues.length - 1];
		}

		if (lastSelected === undefined) {
			// case when selectedValues are empty
			onChange && onChange({ target: { name, value: selectedValues } });
			return;
		} else {
			// Check if the item already exists in the current value
			const exists = value.some((option) => option.name === lastSelected.name);

			let updatedValues;
			if (exists) {
				// Remove the item if it exists
				updatedValues = value.filter((option) => option.name !== lastSelected.name);
			} else {
				// Add the item if it does not exist
				updatedValues = [...value, lastSelected];
			}

			// Additional logic for the 'Injectables' label
			if (name === 'presentation' && updatedValues.some((option) => option.name === InjectablesLabel)) {
				// Exclude injectable options from presentations when "Injectables" is selected
				setIsInjectable(false);
			}

			// Trigger the onChange callback with the updated values
			onChange && onChange({ target: { name, value: updatedValues } });
		}
	};


	const onSearchChange = (e) => setSearch(e.target.value);

	const onInputChange = (e, value, reason) => {
		if (reason === 'clear') setSearch('');
	};

	const handleScroll = (event) => {
		const listboxNode = event.currentTarget;
		if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1 && nextUrl && !loading) {
			setPage(prevPage => prevPage + 1);
		}
	};

	return (
		<Box>
			<MatFormLabel id={name}>
				{label}
				{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
			</MatFormLabel>
			<Autocomplete
				multiple
				freeSolo
				open={open}
				onOpen={() => setOpen(true)}
				// onClose={() => setOpen(false)}
				onBlur={() => {setOpen(false); setSearch('');}}
				onKeyDown={(e) => {
					if (e.code === 'Escape') {
						setOpen(false);
					}
				}}
				loading={loading}
				fullWidth={fullWidth}
				size={size}
				inputValue={search}
				onInputChange={onInputChange}
				value={value}
				onChange={onChangeHandler}
				options={loading ? [...inputOptions, { name: 'Loading...' }] : inputOptions}
				getOptionLabel={(option: any) => option.name}
				renderTags={() => null}
				filterOptions={(options) => {
					// Ensure selected values are included in the dropdown
					const mergedOptions = [
						...value.filter( opt => opt.name.toLowerCase().includes(debounceValue.toLowerCase()) ),
						...options.filter(opt => !value.some(val => val.name === opt.name))
					];
			
					// Sort options: selected ones first
					return mergedOptions.sort((a, b) => {
						const aSelected = value.some((val) => val.name === a.name);
						const bSelected = value.some((val) => val.name === b.name);
						return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
					});
				}}
				ListboxProps={{
					onScroll: handleScroll,
					ref: listboxRef,
				}}
				renderOption={(props, option: any, rest) => (
					option.name === 'Loading...' ? (
						<ListItemButton 
							key={option.name + rest.index} 
							disabled 
							component="span" 
							{...props}
						>
							<Box display='flex' alignItems='center' justifyContent='center' width='100%'>
								<CircularProgress size={15} />
							</Box>
						</ListItemButton>
					) : (
						<ListItem key={option.name + rest.index} {...props}>
							<Box display='flex' alignItems='center'>
								<Checkbox
									checked={value.some((val) => val.name === option.name)}
									style={{ pointerEvents: 'none' }}
								/>
								<Typography variant='body1' sx={{ fontSize: '14px' }}>
									{option.name}
								</Typography>
							</Box>
						</ListItem>
					)
				)}
				renderInput={(params) => (
					<TextFieldWrapper
						onChange={onSearchChange}
						placeholder={placeholder}
						sx={{
							maxHeight: size === MaterialUISizeEnum.SMALL
								? SMALL_VARIANT_INPUT_HEIGHT
								: undefined,
							fontSize: size === MaterialUISizeEnum.SMALL
								? SMALL_VARIANT_FONT_SIZE
								: undefined,
							borderRadius: MEDIUM_BORDER_RADIUS,
							backgroundColor: COLORS.white,
						}}
						{...params}
					/>
				)}
				sx={{
					maxHeight: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_INPUT_HEIGHT
						: undefined,
					fontSize: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_FONT_SIZE
						: undefined,
					borderRadius: MEDIUM_BORDER_RADIUS,
					backgroundColor: COLORS.white,
				}}
			/>
		</Box>
	);
};
