import CognitoClient from './Clients/CognitoClient';
import { ChangePasswordParams } from 'models/interfaces';

export const cognitoChangePassword = async (params: ChangePasswordParams) => {
	try {
		const response = await CognitoClient.post(
			'',
			{
				AccessToken: params.access_token,
				PreviousPassword: params.current_password,
				ProposedPassword: params.new_password,
			},
			{
				headers: {
					'Content-Type': 'application/x-amz-json-1.1',
					'X-Amz-Target': 'AWSCognitoIdentityProviderService.ChangePassword',
				},
			}
		);
		return response.data;
	} catch (error: any) {
		return { error: error?.data?.message || 'An unexpected error occurred' };
	}
};
