import React, { useCallback, useMemo } from 'react';
import { Grid, Paper, Typography } from '@mui/material';

import { BaseCheckbox, SearchInput } from 'components/Inputs';
import { RemoteDataTable } from 'components/Tables';
import { FormWithAdditionaFilters } from 'components/Forms';

import { productColumns } from './columns';
import { searchFilters } from './searchFilters';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { useProductPresentations } from 'services/queries';
import { useSearchParams } from 'hooks';
import { COLORS } from 'utils/consts';


interface ComboInput {
	ingredient: { name: string; id?: string | number } | null;
	strength: { name: string; id?: string | number }[];
}

interface IngredientAndStrengths {
	ingredients: ComboInput[];
	isExactIngredients: boolean;
}

interface SearchInputsIntreface {
	productName: string;
	is_commercial_exclude: boolean;
	is_empty_price_exclude: boolean;
	countries: { name: string; id: string; code: string | number }[];
	presentation: { name: string; id: number }[];
	pack_size: { name: string; id: number }[];
	strength: { name: string; id: number }[];
	ma_holder: { name: string; id: number }[];
	ingredient_and_strengths: IngredientAndStrengths;
}

const initialSearchInputs: SearchInputsIntreface = {
	productName: '',
	is_commercial_exclude: true,
	is_empty_price_exclude: false,
	countries: [],
	presentation: [],
	pack_size: [],
	strength: [],
	ma_holder: [],
	ingredient_and_strengths: { ingredients: [{ ingredient: null, strength: [] }], isExactIngredients: false }
};

const areFiltersActive = (searchInputs, initialSearchInputs) => {
	// Compare each property in searchInputs with initialSe		archInputs
	return Object.keys(initialSearchInputs).some((key) => {
		const initialValue = initialSearchInputs[key];
		const currentValue = searchInputs[key];
		return JSON.stringify(initialValue) !== JSON.stringify(currentValue);
	});
};

export const ProductExplorer = () => {
	const [searchInputs, setSearchInputs, resetSearchParams] = useSearchParams<SearchInputsIntreface>(
		initialSearchInputs
	);


	const filtersActive = areFiltersActive(searchInputs, initialSearchInputs);


	const productQueryParams = useMemo(
		() => {
			const ingredientAndStrengths = searchInputs.ingredient_and_strengths?.ingredients
				.filter((e) => searchInputs.productName !== e.ingredient?.name || e.strength.length > 0) // excludes ingredient if it matches main search exactly and has no strength
				.map((combo) => {
					const ingredientId = combo.ingredient?.id;
					if (!ingredientId) return;
					const strengthIds = combo.strength.map((item) => item.id).join(',');
					return `${ingredientId}:${strengthIds}`;
				})
				.filter(Boolean) // Filter out any invalid combinations
				.join(';');

			const ingredientsCount = searchInputs.ingredient_and_strengths.ingredients.filter((e) => searchInputs.productName !== e.ingredient?.name || e.strength.length > 0).length;

			const exactIngredientsCount =
				searchInputs.ingredient_and_strengths.isExactIngredients === true && ingredientsCount > 0
					? ingredientsCount
					: undefined;

		
			return {
				search: searchInputs.productName,
				country__in: searchInputs.countries.map((val) => val.id).join(','),
				company__in: searchInputs.ma_holder.map((val) => val.id).join(','),
				product_pack__presentation__in: searchInputs.presentation
					.filter((val) => val.id)
					.map((val) => val.id)
					.join(','),
				product_pack__pack_size__in: searchInputs.pack_size
					.map((val) => val.id)
					.join(','),
				product_pack__active_ingredients__strength__in: searchInputs.strength
					.map((val) => val.id)
					.join(','),
				// active_ingredient__in: ingredientsWithoutStrengths || undefined, // Include only if not empty
				ingredient_and_strengths: ingredientAndStrengths || undefined, // Include only if not empty
				exact_ingredients_count: exactIngredientsCount,
				is_commercial_exclude: searchInputs.is_commercial_exclude
					? !searchInputs.is_commercial_exclude
					: null,
				...(searchInputs.is_empty_price_exclude
					? {
						is_price_empty: !searchInputs.is_empty_price_exclude,
					}
					: null),
				...(searchInputs.presentation.find(val => val.name === 'Injectables')
					? {
						is_injectable: true
					} : null)
			};},
		[
			searchInputs.countries,
			searchInputs.ma_holder,
			searchInputs.presentation,
			searchInputs.pack_size,
			searchInputs.strength,
			searchInputs.is_commercial_exclude,
			searchInputs.is_empty_price_exclude,
			JSON.stringify(searchInputs.ingredient_and_strengths),
			searchInputs.productName,
		]
	);

	const {
		data: prodData,
		state: prodState,
		setPagination: prodSetPagination,
		rowCount: prodRowCount,
		setSorting: prodSorting,
		pageCount: prodPageCount,
		setPageCount: prodSetPageCount,
	} = useQueryPagination(
		useProductPresentations,
		null,
		null,
		productQueryParams
	);

	const onChangeHandler = useCallback((e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.value,
		}));
	}, []);

	const onChangeCheckboxHandler = (e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.checked,
		}));
	};

	return (
		<Paper elevation={0} sx={{padding: 0, borderRadius: '10px'}}>
			<RemoteDataTable
				state={prodState}
				data={prodData}
				pageCount={prodPageCount}
				onRowsPerPageChange={prodSetPageCount}
				onPaginationChange={prodSetPagination}
				rowCount={prodRowCount}
				columns={productColumns}
				enableRowSelection={false}
				enablePagination
				manualSorting
				enableMultiSort={true}
				onSortingChange={prodSorting}
				renderCustomActions={({table}) => (
					<Grid container flexDirection={'column'}>
						<Grid 
							item 
							container 
							sx={{
								background: COLORS.background,
								padding: table.getState().isFullScreen ? '20px 20px 0' : '5px 0',
							}}
						>
							<FormWithAdditionaFilters
								parentValue={searchInputs.productName}
								filters={searchFilters}
								searchInputs={searchInputs}
								onChangeHandler={onChangeHandler}
								onResetHandler={resetSearchParams}
								gridWidth={4}
								fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
								isFullScreen={table.getState().isFullScreen}
							>
								<Grid item xs={6} md={3}>
									<SearchInput
										onChange={onChangeHandler}
										value={searchInputs.productName}
										name={'productName'}
										placeholder={'Search by name, active ingredient, or ATC code'}
										fullWidth
									/>
								</Grid>
								<Grid item alignSelf='flex-end' pb={1}>
									<BaseCheckbox
										onChange={onChangeCheckboxHandler}
										checked={searchInputs.is_commercial_exclude}
										name={'is_commercial_exclude'}
										label={'Exclude non-commercialised lines'}
									/>
								</Grid>
								<Grid item alignSelf='flex-end' pb={1}>
									<BaseCheckbox
										onChange={onChangeCheckboxHandler}
										checked={searchInputs.is_empty_price_exclude}
										name={'is_empty_price_exclude'}
										label={'Exclude empty price'}
									/>
								</Grid>
							</FormWithAdditionaFilters>
						</Grid>
						<Grid item sx={{background: COLORS.background}}>
							<Typography fontWeight='600' py={2} px={2} pt={3} sx={{ background: COLORS.white, borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
								{filtersActive ? 'Products' : 'All Products'}
								{prodRowCount ? ` (${prodRowCount})` : ''}
							</Typography>
						</Grid>
					</Grid>
				)}
			/>
		</Paper>
	);
};
